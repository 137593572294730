import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DemoDirective } from './directives/demo/demo.directive';
import { DemoPipe } from './pipes/demo/demo.pipe';
import { CardInfoComponent } from './components/card-info/card-info.component';
import { CardRequestComponent } from './components/card-request/card-request.component';
import { BtnBlockBottomComponent } from './components/btn-block-bottom/btn-block-bottom.component';
import { ModalBottomComponent } from './components/modal-bottom/modal-bottom.component';
import { MsgSuccessComponent } from './components/msg-success/msg-success.component';
import { AnswerSurveyComponent } from './components/answer-survey/answer-survey.component';
import { InputDatepickerComponent } from './components/input-datepicker/input-datepicker.component';
import { ModalPageComponent } from './components/modal-page/modal-page.component';
import { StepsNavComponent } from './components/steps-nav/steps-nav.component';
import { AnswerButtonsComponent } from './components/answer-buttons/answer-buttons.component';
import { AnswerTextComponent } from './components/answer-text/answer-text.component';
import { AnswerRangeComponent } from './components/answer-range/answer-range.component';
import { CardRequestPendingComponent } from './components/card-request-pending/card-request-pending.component';
import { NavTabsTopComponent } from './components/nav-tabs-top/nav-tabs-top.component';
import { ExtractInitialsPipe } from '@shared/pipes/extract-initials/extract-initials.pipe';
// Ionic
import { IonicArqModule } from '@app/ionic.module';
import { AnswerStarsComponent } from './components/answer-stars/answer-stars.component';
import { AnswerEmojisComponent } from './components/answer-emojis/answer-emojis.component';
import { CardErrorComponent } from './components/card-error/card-error.component';
import { HeaderDesktopComponent } from './components/header-desktop/header-desktop.component';
import { SpaceResponsePipe } from './pipes/space-response/space-response.pipe';
import { DataTextPipe } from './pipes/data-text/data-text.pipe';

/**
 * This module should not contain providers (they'd be on core module)
 * and must be imported on every module from (+modules) so as
 * to use its elements (components, directives and pipes)
 *
 * This module also exports Ionic elements from IonicArqModule,
 * Flex Layout and Material/Bootstrap resources from
 * visuals module, so it is mandatory to import it on every module
 * from (+views) that needs to use them
 */
@NgModule({
  declarations: [/* Components, directives and pipes to be shared */
    DemoDirective,
    DemoPipe,
    CardInfoComponent,
    CardRequestComponent,
    CardRequestPendingComponent,
    BtnBlockBottomComponent,
    ModalBottomComponent,
    MsgSuccessComponent,
    InputDatepickerComponent,
    AnswerSurveyComponent,
    ModalPageComponent,
    StepsNavComponent,
    AnswerButtonsComponent,
    AnswerTextComponent,
    AnswerRangeComponent,
    AnswerStarsComponent,
    AnswerEmojisComponent,
    NavTabsTopComponent,
    ExtractInitialsPipe,
    CardErrorComponent,
    HeaderDesktopComponent,
    SpaceResponsePipe,
    DataTextPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicArqModule,
    TranslateModule
  ],
  exports: [/* Components, directives and pipes to be shared */
    DemoDirective,
    DemoPipe,
    FormsModule,
    ReactiveFormsModule,
    IonicArqModule,
    TranslateModule,
    CardInfoComponent,
    CardRequestComponent,
    CardRequestPendingComponent,
    BtnBlockBottomComponent,
    ModalBottomComponent,
    MsgSuccessComponent,
    InputDatepickerComponent,
    AnswerSurveyComponent,
    ModalPageComponent,
    StepsNavComponent,
    AnswerButtonsComponent,
    AnswerTextComponent,
    AnswerRangeComponent,
    AnswerStarsComponent,
    AnswerEmojisComponent,
    NavTabsTopComponent,
    ExtractInitialsPipe,
    CardErrorComponent,
    HeaderDesktopComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [
    ModalPageComponent,
    ModalBottomComponent
  ]

})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [/* In case (rare) we want to share providers too, we'll use the .forRoot() method when importing the module*/]
    };
  }
}
