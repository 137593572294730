  <!-- CONTAINER CARD STATUS SOLICITUD DÍA EN CURSO -->
  <ion-card class="ion-padding request-card current-day"
            [ngClass]="{'selected': selectRequest }"
            *ngFor="let request of spacesRequestedPending, let i = index">
    <ion-row class="mb-2 d-flex ion-justify-content-between align-items-center">
      <div class="request-card-date">
       {{ request.dateExtended }}
      </div>
      <div class="request-card-status desktop" *ngIf="request.type === 'PARKING_PLACE' && request.disabled">
        <i class="icon-accessibility-on"></i>
      </div>
      <div class="request-card-status parking" *ngIf="request.type === 'PARKING_PLACE'">
        <i class="icon-car"></i>
      </div>
      <div class="request-card-status desktop" *ngIf="request.type === 'WORK_PLACE'">
        <i class="icon-monitor"></i>
      </div>
    </ion-row>
    <ion-row>
      <button (click)="deleteRequest(request)" class="ion-activatable ripple-parent">
        <ion-icon class="icn-delete" name="trash-outline"></ion-icon>
        <ion-ripple-effect></ion-ripple-effect>
        {{ 'BUTTONS.DELETE' | translate }}
      </button>
    </ion-row>
  </ion-card>

  <!-- NO REQUESTS -->
  <ion-card class="ion-padding request-card" *ngIf="!spacesRequestedPending">
    <ion-card-content class="big blue ion-text-center" [translate]="'REQUEST.NO_PENDING'"></ion-card-content>
    <!-- <ion-row class="d-flex ion-justify-content-between" [translate]="'REQUEST.NO_PENDING'">
    </ion-row>-->
  </ion-card>
