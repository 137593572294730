<!-- CONTAINER CARD "NO HAY SOLICITUDES" -->
<ion-card class="ion-padding d-flex flex-column ion-align-items-center">
  <!-- ILUSTRACIÓN -->
  <div class="card-illustration" *ngIf="image">
    <img [src]="'assets/img/illustrations/' + image" alt="" />
  </div>
  <!-- TEXT -->
  <ion-card-content class="big ion-text-center" *ngIf="text">{{ text | translate}}</ion-card-content>
  <!-- BOTONES -->
  <!-- PRIMARIO OSCURO -->
  <ion-button shape="round" expand="block" (click)="goToRoute()" *ngIf="textButton">{{ textButton | translate}}</ion-button>
  <!-- PRIMARIO BLANCO -->
  <!-- <ion-button shape="round" expand="block" fill="outline">Block Button</ion-button> -->
  <!-- SECUNDARIO OSCURO -->
  <!-- <ion-button shape="round" expand="block" color="secondary">Block Button</ion-button> -->
  <!-- SECUNDARIO BLANCO -->
  <!-- <ion-button shape="round" expand="block" color="secondary" fill="outline">Block Button</ion-button> -->
  <!-- <ion-button color="tertiary">Botón encuestas</ion-button> -->
  <!-- <ion-button color="tertiary" fill="outline">Botón encuestas</ion-button> -->
</ion-card>
