<!-- BLUE GREETINGS CONTAINER -->
<div class="display-responsive-control" *ngIf="infoHeader">
  <div class="pt-4" *ngIf="infoHeader.headerDesktopUserName">
    <div class="container-md">
      <div class="d-flex align-items-center">
        <!-- GREETINGS AVATAR -->
        <ion-avatar class="d-flex ion-justify-content-center ion-align-items-center">
          <img src="assets/img/profile.svg"/>
          <div class="avatar-acronym">
            {{ userInitials }}
          </div>
        </ion-avatar>
        <!-- GREETINGS AND NAME -->
        <div class="greeting-container pl-3">
          <div class="bold ">
            {{ 'HEADER.HELLO' | translate }} {{ userCompleteName }}
          </div>
          <div>
            {{ 'HEADER.WELCOME_AREA' | translate }} <span [innerHTML]="infoHeader.title | translate"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- MAQUETA BDGITAL 1.0 -->
  <!-- <div class="container-md request-space-section mb-1" *ngIf="!infoHeader.headerDesktopSurveyName && !infoHeader.headerDesktopUserName">
    <div class="d-flex">
      <div class="card-title" [translate]="'REQUEST.FORM.TITLE'"></div>
    </div>
    <div class="request-space-subtitle" [translate]="'REQUEST.HEADER'"></div>
  </div> -->

<!-- MAQUETA BDGITAL 2.0 -->
  <div class="container-md request-space-section ion-padding" *ngIf="!infoHeader.headerDesktopSurveyName && !infoHeader.headerDesktopUserName">
    <div class="d-flex">
      <h4 [translate]="'REQUEST.FORM.TITLE_2'"></h4>
    </div>
    <div class="request-space-subtitle" [translate]="'REQUEST.HEADER_2'"></div>
  </div>
</div>
