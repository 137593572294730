import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// ** It's very important to keep this module empty **
// We will handle routes from each module (+modules) and
// the main app router will be the one from core module

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
