<div class="nav-tab-container d-flex flex-row">
  <!-- GREY STYLES: .select -->
  <div class="nav-tab-item ion-text-center relative"
       *ngFor="let tab of tabList; let selectTab = last; let first = first"
       [ngClass]="{ select: tab.isActive }">
    <div class="nav-tab-inner" (click)="switchTab(tab)">
      <div class="color-tab" [ngClass]="{ colorIn: tab.isActive }">
      </div>
      <i [ngClass]="'icon-' + tab.icon"></i>
      <div class="nav-tab-text">
        {{ tab.text | translate }}
      </div>
    </div>
  </div>
</div>
  <!-- Default Segment -->
  <!-- <ion-segment mode="ios" color="test">
    <ion-segment-button  *ngFor="let tab of tabList; let selectTab = last" (click)="switchTab(tab)" value="{{ tab.text | translate }}">
      <ion-label>{{ tab.text | translate }}</ion-label>
    </ion-segment-button>
  </ion-segment> -->


<div class="scrollable-area">
  <app-card-request *ngIf="selectTab.slug === TabSlug.Approve"></app-card-request>
  <app-card-request-pending *ngIf="selectTab.slug === TabSlug.Pending"></app-card-request-pending>
</div>

