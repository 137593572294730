import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomLoggerService, LogTypes } from '@core/services/logger/logger.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ErrorService } from '@core/services/error/error.service';
import { AppUrls } from '@config/app-urls.config';
import { Router } from '@angular/router';
import { ConfigService } from '@core/services/config/config.service';
import { StorageService } from '@core/services/storage/storage.service';
import { Platform } from '@ionic/angular';

/**
 * Interceptor for handling http errors
 */
@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {

  private logType: LogTypes;
  private envConfig;

  constructor(
    private readonly logger: CustomLoggerService,
    private readonly utils: UtilsService,
    private readonly errorService: ErrorService,
    private router: Router,
    private readonly config: ConfigService,
    private readonly platform: Platform,
    private readonly storageService: StorageService
  ) {
    this.envConfig = this.config.config;
  }

  /**
   * Catalog of errors for custom error mapping
   */
  errorsCatalogue(errorCode: string): string {
    const errors = this.envConfig.app.properties.errors;
    return errors[errorCode] || 'COMMON.ERROR.GENERIC';
  }

  /**
   * Helper function to map the received errors against the given config so as to return a custom error
   * message or structure
   *
   * If the received error contains the property 'httpMessage', it will be considered. Otherwise it will
   * try to match the 'errorDefault' property for the specific endpoint. If no 'errorDefault' is defined
   * for the endpoint, it will return a generic error 'COMMON.ERROR.GENERIC'
   *
   * @param error: Error | HttpErrorResponse | any. This parameter is mapped against the endpoints config to retrieve
   * custom error messages or structure
   *
   * @param request: HttpRequest. The request associated to the received error. It will be used to return
   * the appropriate default error if no error message is found on the error response
   */
  mapErrors(
    error: Error | HttpErrorResponse | any,
    request: HttpRequest<any>
  ): Error | HttpErrorResponse {
    const endpoint = this.utils.matchRequestEndpoint(request);
    const errorMessage: any = error.error ? this.getErrorWithJSONParse(error.error) : null;
    const custom = {
      customMessage:
        errorMessage && errorMessage.code
          ? this.errorsCatalogue(errorMessage.code)
          : (endpoint &&
            endpoint.restOptions &&
            endpoint.restOptions.errorDefault) ||
          'COMMON.ERROR.GENERIC'
    };
    return { ...custom, ...error };
  }

  /**
   * Helper function for customizing the error handling
   *
   * It considers the error type in order to assign the proper kind of logging and handling
   *
   * @param error: Error | HttpErrorResponse
   */
  traceError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof Error) {
        // Usually frontend errors such as issues on RxJS
        this.logger.log(error, this.logType.warn);
      } else {
        // Backend errors
        this.logger.log(error, this.logType.error);
      }
    } else {
      // Anything else
      this.logger.log(error);
    }
  }

  /**
   * Sends the retrieved error to the ErrorService so it gets handled and passed to the corresponding view
   * And navigates to the error view
   * @param error: Error
   */
  async showError(error: Error | HttpErrorResponse | any) {
    if (error.status === 401) {
      this.errorService.setErrors('La sesión ha expirado', '/' + AppUrls.AppLogin);
      if (this.platform.is('hybrid')) {
        await this.storageService.removeSecure('ACCESS_TOKEN');
        await this.storageService.removeSecure('REFRESH_TOKEN');

      } else {
        await this.storageService.remove('ACCESS_TOKEN');
        await this.storageService.remove('REFRESH_TOKEN');
      }
    } else {
      this.errorService.setErrors(error.customMessage, this.router.url);
    }

    this.router.navigate([AppUrls.AppError], { replaceUrl: true });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.logType = {
      log: 'log',
      warn: 'warn',
      error: 'error'
    };

    if (!navigator.onLine) {
      this.logger.log(`It appears that you don't have internet connection`);
    }

    return next.handle(req)
      .pipe(
        catchError((error: Error | HttpErrorResponse) => {
          error = this.mapErrors(error, req);
          this.traceError(error);
          this.showError(error);
          return throwError(error);
        })
      );
  }

  /**
   * This method will get the error parsing the JSON in string format, there is additional control in order to control double stringed error
   * @param error: the error in string format containing the JSON
   */
  private getErrorWithJSONParse(error): any {
    const parsedError = error && !(error instanceof Object) && !error.startsWith('<') ? JSON.parse(error) : error;

    return !(parsedError instanceof Object) ? this.getErrorWithJSONParse(parsedError) : parsedError;
  }
}
