/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IdType } from '../models/id-type';
import { PageableRequest } from '../models/pageable-request';
import { PageableRequestReport } from '../models/pageable-request-report';
import { ReservationDateSpacesType } from '../models/reservation-date-spaces-type';
import { ReservationEmployee } from '../models/reservation-employee';
import { ReservationPageResponse } from '../models/reservation-page-response';
import { ReservationResponseList } from '../models/reservation-response-list';
import { SpaceType } from '../models/space-type';


/**
 * Operations about employees reservation
 */
@Injectable({
  providedIn: 'root',
})
export class ReservationsService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation deleteAssignedSpacesEmployee
   */
  static readonly DeleteAssignedSpacesEmployeePath = '/spaces/reservations/{Id}';

  /**
   * Delete assigned spaces by id (Deprecated).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `deleteAssignedSpacesEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssignedSpacesEmployee$Response(params: {

    /**
     * reservation id
     */
    Id: IdType;

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(ReservationsService.DeleteAssignedSpacesEmployeePath, 'delete');
    if (params) {
      req.path('Id', params.Id, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Delete assigned spaces by id (Deprecated).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `deleteAssignedSpacesEmployee$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssignedSpacesEmployee(params: {

    /**
     * reservation id
     */
    Id: IdType;

  }): Observable<void> {

    return this.deleteAssignedSpacesEmployee$Response(params).pipe(
      map(r => r as void)
    );
  }

  /**
   * Path part for operation deleteAssignedSpaceEmployeeV2
   */
  static readonly DeleteAssignedSpaceEmployeeV2Path = '/spaces/reservations/{id}/{spaceType}';

  /**
   * Delete assigned space type to current employee for a day by id (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `deleteAssignedSpaceEmployeeV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssignedSpaceEmployeeV2$Response(params: {

    /**
     * reservation id
     */
    id: IdType;

    /**
     * reservation id
     */
    spaceType: SpaceType;

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(ReservationsService.DeleteAssignedSpaceEmployeeV2Path, 'delete');
    if (params) {
      req.path('id', params.id, {});
      req.path('spaceType', params.spaceType, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Delete assigned space type to current employee for a day by id (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `deleteAssignedSpaceEmployeeV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssignedSpaceEmployeeV2(params: {

    /**
     * reservation id
     */
    id: IdType;

    /**
     * reservation id
     */
    spaceType: SpaceType;

  }): Observable<void> {

    return this.deleteAssignedSpaceEmployeeV2$Response(params).pipe(
      map(r => r as void)
    );
  }

  /**
   * Path part for operation getAssignedSpaceEmployee
   */
  static readonly GetAssignedSpaceEmployeePath = '/spaces/reservations/report';

  /**
   * Retrieve the list of reserved spaces between two dates.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getAssignedSpaceEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedSpaceEmployee$Response(params?: {

    /**
     * Date from query.
     */
    dateFrom?: string;

    /**
     * Date to query.
     */
    dateTo?: string;

    /**
     * Pagination and sort
     */
    pagination?: PageableRequest;

  }): Observable<ReservationPageResponse> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(ReservationsService.GetAssignedSpaceEmployeePath, 'get');
    if (params) {
      req.query('dateFrom', params.dateFrom, {});
      req.query('dateTo', params.dateTo, {});
      req.query('pagination', params.pagination, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as ReservationPageResponse;
      })
    );
  }

  /**
   * Retrieve the list of reserved spaces between two dates.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAssignedSpaceEmployee$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedSpaceEmployee(params?: {

    /**
     * Date from query.
     */
    dateFrom?: string;

    /**
     * Date to query.
     */
    dateTo?: string;

    /**
     * Pagination and sort
     */
    pagination?: PageableRequest;

  }): Observable<ReservationPageResponse> {

    return this.getAssignedSpaceEmployee$Response(params).pipe(
      map(r => r as ReservationPageResponse)
    );
  }

  /**
   * Path part for operation reportAssignedSpaceEmployeeV2
   */
  static readonly ReportAssignedSpaceEmployeeV2Path = '/spaces/reservations/report';

  /**
   * Retrieve an excel with the list of reserved spaces between two dates (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `reportAssignedSpaceEmployeeV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportAssignedSpaceEmployeeV2$Response(params?: {

    /**
     * Date from query.
     */
    dateFrom?: string;

    /**
     * Date to query.
     */
    dateTo?: string;

  }): Observable<Blob> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(ReservationsService.ReportAssignedSpaceEmployeeV2Path, 'post');
    if (params) {
      req.query('dateFrom', params.dateFrom, {});
      req.query('dateTo', params.dateTo, {});
    }

    const sendReq = req.build({
      responseType: 'blob',
      accept: 'application/vnd.ms-excel'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Blob;
      })
    );
  }

  /**
   * Retrieve an excel with the list of reserved spaces between two dates (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `reportAssignedSpaceEmployeeV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  reportAssignedSpaceEmployeeV2(params?: {

    /**
     * Date from query.
     */
    dateFrom?: string;

    /**
     * Date to query.
     */
    dateTo?: string;

  }): Observable<Blob> {

    return this.reportAssignedSpaceEmployeeV2$Response(params).pipe(
      map(r => r as Blob)
    );
  }

  /**
   * Path part for operation getReservationsByDaysByUserPageableV2
   */
  static readonly GetReservationsByDaysByUserPageableV2Path = '/spaces/reservations/report/byUser';

  /**
   * Retrieve the list of reserved spaces between two dates by user (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getReservationsByDaysByUserPageableV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReservationsByDaysByUserPageableV2$Response(params?: {

    /**
     * Query with pagination and sort
     */
    pagination?: PageableRequestReport;

  }): Observable<ReservationPageResponse> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(ReservationsService.GetReservationsByDaysByUserPageableV2Path, 'get');
    if (params) {
      req.query('pagination', params.pagination, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as ReservationPageResponse;
      })
    );
  }

  /**
   * Retrieve the list of reserved spaces between two dates by user (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getReservationsByDaysByUserPageableV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getReservationsByDaysByUserPageableV2(params?: {

    /**
     * Query with pagination and sort
     */
    pagination?: PageableRequestReport;

  }): Observable<ReservationPageResponse> {

    return this.getReservationsByDaysByUserPageableV2$Response(params).pipe(
      map(r => r as ReservationPageResponse)
    );
  }

  /**
   * Path part for operation markUsedAssignedSpaceEmployeeV2
   */
  static readonly MarkUsedAssignedSpaceEmployeeV2Path = '/spaces/reservations/{id}/used';

  /**
   * Mark used assigned space to current employee (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `markUsedAssignedSpaceEmployeeV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  markUsedAssignedSpaceEmployeeV2$Response(params: {

    /**
     * Space id
     */
    id: IdType;

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(ReservationsService.MarkUsedAssignedSpaceEmployeeV2Path, 'patch');
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Mark used assigned space to current employee (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `markUsedAssignedSpaceEmployeeV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  markUsedAssignedSpaceEmployeeV2(params: {

    /**
     * Space id
     */
    id: IdType;

  }): Observable<void> {

    return this.markUsedAssignedSpaceEmployeeV2$Response(params).pipe(
      map(r => r as void)
    );
  }

  /**
   * Path part for operation addReservationV2
   */
  static readonly AddReservationV2Path = '/spaces/reservations/addReservation';

  /**
   * Add user reservations (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `addReservationV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addReservationV2$Response(params: {

    /**
     * Reservation Data.
     */
    body: ReservationEmployee
  }): Observable<ReservationResponseList> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(ReservationsService.AddReservationV2Path, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as ReservationResponseList;
      })
    );
  }

  /**
   * Add user reservations (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `addReservationV2$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addReservationV2(params: {

    /**
     * Reservation Data.
     */
    body: ReservationEmployee
  }): Observable<ReservationResponseList> {

    return this.addReservationV2$Response(params).pipe(
      map(r => r as ReservationResponseList)
    );
  }

  /**
   * Path part for operation getAvailableDatesWorkPlaceV2
   */
  static readonly GetAvailableDatesWorkPlaceV2Path = '/spaces/reservations/datesWorkPlace';

  /**
   * get available dates working places (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getAvailableDatesWorkPlaceV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableDatesWorkPlaceV2$Response(params?: {

  }): Observable<ReservationDateSpacesType> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(ReservationsService.GetAvailableDatesWorkPlaceV2Path, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as ReservationDateSpacesType;
      })
    );
  }

  /**
   * get available dates working places (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAvailableDatesWorkPlaceV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableDatesWorkPlaceV2(params?: {

  }): Observable<ReservationDateSpacesType> {

    return this.getAvailableDatesWorkPlaceV2$Response(params).pipe(
      map(r => r as ReservationDateSpacesType)
    );
  }

  /**
   * Path part for operation getAvailableDatesParkingPlaceV2
   */
  static readonly GetAvailableDatesParkingPlaceV2Path = '/spaces/reservations/datesParkingPlace';

  /**
   * get available dates parking places (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getAvailableDatesParkingPlaceV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableDatesParkingPlaceV2$Response(params?: {

  }): Observable<ReservationDateSpacesType> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(ReservationsService.GetAvailableDatesParkingPlaceV2Path, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as ReservationDateSpacesType;
      })
    );
  }

  /**
   * get available dates parking places (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAvailableDatesParkingPlaceV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableDatesParkingPlaceV2(params?: {

  }): Observable<ReservationDateSpacesType> {

    return this.getAvailableDatesParkingPlaceV2$Response(params).pipe(
      map(r => r as ReservationDateSpacesType)
    );
  }

}
