<div class="bottom-msg-container">
  <div class="max-width-control">
    <div class="bottom-msg-icon">
      <img src="assets/img/icons/icon-alert.png" alt="" *ngIf="!isSuccess"/>
      <img src="assets/img/icons/icon-ok.png" alt="" *ngIf="isSuccess"/>
    </div>
    <div class="bottom-msg-txt ion-padding" *ngIf="!isSuccess">
      {{ 'MODAL.CANCEL_MSG' | translate }}
    </div>
    <div class="bottom-msg-txt ion-padding" *ngIf="isSuccess">
      {{ 'MODAL.CANCEL_MSG_SUCCESS' | translate }}
    </div>
    <div class="bottom-msg-btn ion-padding">
      <ion-button shape="round" expand="block" color="secondary" fill="outline" (click)="dismissModal()"
                  class="ion-margin-bottom" *ngIf="!isSuccess"> {{ 'MODAL.NO' | translate }}</ion-button>
      <ion-button shape="round" expand="block" color="secondary" (click)="deleteRequest()" class="ion-margin-bottom"
                  *ngIf="!isSuccess"> {{ 'MODAL.YES' | translate }}</ion-button>
      <ion-button shape="round" expand="block" color="secondary" (click)="dismissModal()"
                  *ngIf="isSuccess"> {{ 'MODAL.ACCEPT' | translate }}</ion-button>
    </div>
  </div>
</div>
