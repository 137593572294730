import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '@shell/header/services/header.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExtractInitialsPipe } from '@shared/pipes/extract-initials/extract-initials.pipe';
import { ModalPageComponent } from '@app/shared/components/modal-page/modal-page.component';
import { ModalController } from '@ionic/angular';
import { AppUrls } from '@config/app-urls.config';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ExtractInitialsPipe],
})
export class HeaderComponent implements OnInit, OnDestroy {
  infoHeader: Header;
  // FIXME: escape accent from name, the problem could be in the OAuth response, check if the name is encoded
  userCompleteName: string;
  userInitials: string;
  tooltip = false;
  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    private readonly auth: AuthService,
    public headerService: HeaderService,
    private extractInitialsPipe: ExtractInitialsPipe,
    public modalController: ModalController,
    private platform: Platform,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.headerService
      .getInfoHeaderObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe((data: Header) => {
        this.infoHeader = data;
      });
    this.headerService
      .getActualUser()
      .pipe(takeUntil(this.destroyed))
      .subscribe((userCompleteName: string) => {
        this.headerService.setName(userCompleteName);
        this.userCompleteName = userCompleteName;
        this.userInitials = this.extractInitialsPipe.transform(
          userCompleteName
        );
      });

  }

  async exit() {
    this.tooltip = false;
    const modal = await this.modalController.create({
      component: ModalPageComponent,
      cssClass: 'survey-modal',
      initialBreakpoint: 0.5,
      breakpoints: [0, 1],
      componentProps: {
        data: {
          image: 'assets/img/illustrations/alert-out.png',
          text: 'MODAL_EXIT.TEXT',
          exit: true,
          firstButton: { text: 'MODAL_EXIT.FIRST_BUTTON_TEXT' },
          secondButton: { text: 'MODAL_EXIT.SECOND_BUTTON_TEXT', action: 'exit' }
        }
      },
      backdropDismiss: false,
    });
    modal.present();
    modal.onWillDismiss().then((modalData) => {
      if (modalData.data.dismissed && (modalData.data && modalData.data.data && modalData.data.data.exit)) {
        this.auth.oAuthLogout().then(() => {
          if (!this.platform.is('mobileweb') && (this.platform.is('ios') || this.platform.is('android'))) {
            this.router.navigate([AppUrls.AppLogin], { replaceUrl: true });
          }
          this.headerService.isHeaderVisible = false;
        }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}

export interface Header {
  title: string;
  nameSurvey?: string;
  dateOpen?: string;
  dateClose?: string;
  headerDesktopVisible?: boolean;
  headerDesktopUserName?: boolean;
  headerDesktopSurveyName?: boolean;
}
