import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { TranslationsService } from '@core/services/translations/translations.service';

@Component({
  selector: 'app-answer-range',
  templateUrl: './answer-range.component.html',
  styleUrls: ['./answer-range.component.scss'],
})
export class AnswerRangeComponent implements OnInit, OnChanges {
  @Input() question;
  message: any;
  @Output() next: EventEmitter<any> = new EventEmitter();
  enabled: boolean;
  focus: boolean;
  rangeValue: number;
  maxLabel: number | string;
  minLabel: number | string;

  constructor(
    private translations: TranslationsService
  ) {
  }

  ngOnInit(): void {
    // Check if previous value has been already set
    if (typeof this.question.value !== 'undefined' &&
      !isNaN(this.question.value)) {
      this.rangeValue = this.question.value;
      this.focus = true;
      this.enable(this.rangeValue);
    }
    this.maxLabel = this.question.interval.right.label || this.question.interval.right.value;
    this.minLabel = this.question.interval.left.label || this.question.interval.left.value;

    this.writeMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propertyName in changes) {
      // tslint:disable-next-line
      const changedProperty = changes[propertyName];
      if (propertyName === 'question') {
        this.updateRange(changedProperty);
      }
    }
  }

  assignRange($event): void {
    this.rangeValue = $event.target.value;
    this.enable(this.rangeValue);
  }

  changeFocus(focus: boolean): void {
    this.focus = focus;
  }

  nextRange(): void {
    if (!this.focus) {
      return;
    }
    this.next.emit({value: this.rangeValue});
    this.focus = !this.focus;
  }

  private enable(rangeValue: any): void {
    this.enabled = !!rangeValue && this.focus;
  }

  private updateRange(changedProperty): void {
    this.rangeValue = changedProperty.currentValue.value || this.question.interval.left.value;
    this.maxLabel = this.question.interval.right.label || this.question.interval.right.value;
    this.minLabel = this.question.interval.left.label || this.question.interval.left.value;
    this.writeMessage();
    this.changeFocus(!!changedProperty.currentValue.value);
    this.enable(this.rangeValue);
  }

  private writeMessage(): void {
    const minValue = this.question.interval.left.value;
    const maxValue = this.question.interval.right.value;
    this.message = this.translations.instant('SURVEYS.INPUT_RANGE_MESSAGE.START') +
      minValue + this.translations.instant('SURVEYS.INPUT_RANGE_MESSAGE.END') +
      maxValue + this.translations.instant('SURVEYS.INPUT_RANGE_MESSAGE.BEING') +
      minValue + this.translations.instant('SURVEYS.INPUT_RANGE_MESSAGE.WORST') +
      maxValue + this.translations.instant('SURVEYS.INPUT_RANGE_MESSAGE.BEST');
  }
}
