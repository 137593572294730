import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { HeaderService } from '@shell/header/services/header.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExtractInitialsPipe } from '@shared/pipes/extract-initials/extract-initials.pipe';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Header } from '@app/shell/header/components/header.component';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  providers: [ExtractInitialsPipe],
})
export class HeaderDesktopComponent implements OnInit, OnDestroy {
  public infoHeader: Header;
  public userCompleteName: string;
  public userInitials: string;
  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    public headerService: HeaderService,
    private extractInitialsPipe: ExtractInitialsPipe,
    public modalController: ModalController,
    public route: Router
  ) { }

  ngOnInit(): void {
    this.headerService
      .getInfoHeaderObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe((data: Header) => {
        this.infoHeader = data;
      });

    this.userCompleteName = this.headerService.getName();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
