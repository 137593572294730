import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-answer-stars',
  templateUrl: './answer-stars.component.html',
  styleUrls: ['./answer-stars.component.scss'],
})
export class AnswerStarsComponent implements OnInit {

  @Input() question;
  // TODO review propagation of translated message
  @Input() message: string;
  @Output() next: EventEmitter<any> = new EventEmitter();
  stars: number[] = [];
  checked: number[] = [];

  constructor() {
  }

  ngOnInit() {
    this.stars = [...Array(this.question.interval.right.value).keys()];
    this.checkStars();
  }

  nextStar($event, icon): void {
    this.next.emit({value: $event.target.value, respIcon: icon});
    $event.target.checked = false;
  }

  private checkStars(): void {
    for (let i = 0; i < this.stars.length; i++) {
      if (this.stars[i + 1] === this.question.value) {
        this.checked[i] = this.stars[i];
      }
    }
  }
}
