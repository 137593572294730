import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private err$ = new BehaviorSubject<CustomError>(null);

  setErrors(message: string, url: string) {
    const error = new CustomError();
    error.url = url;
    error.message = message;
    this.err$.next(error);
  }

  public getError() {
    return this.err$;
  }

  public resetError() {
    return this.err$.next(null);
  }
}

export class CustomError {
  status: string | number;
  message: string;
  buttonName?: string;
  url?: string;

  constructor() {
    this.message = '';
    this.status = '';
  }
}
