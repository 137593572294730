import { Component, OnInit, OnChanges, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-answer-emojis',
  templateUrl: './answer-emojis.component.html',
  styleUrls: ['./answer-emojis.component.scss'],
})
export class AnswerEmojisComponent implements OnInit, OnChanges {
  @Input() message: string;
  @Input() question;
  @Output() next: EventEmitter<any> = new EventEmitter();
  emojis: any[]  = [];

  constructor() {}

  ngOnInit() {
    this.populateEmojis();
    this.checkEmojis();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propertyName in changes) {
      // tslint:disable-next-line
      const changedProperty = changes[propertyName];
      if (!changedProperty.isFirstChange()) {
        this.populateEmojis();
        this.checkEmojis();
      }
    }
  }

  nextEmoj($event, icon): void {
    this.next.emit({value: $event.target.value, respIcon: icon});
    $event.target.checked = false;
  }

  private checkEmojis(): void {
    // tslint:disable-next-line
    for (let i = 0; i < this.emojis.length; i++) {
      if (this.emojis[i].value === this.question.value) {
        this.emojis[i].checked = true;
      }
    }
  }

  private populateEmojis(): void {
    if (this.question.interval.right.value === 3) {
      this.emojis = [
        { icon: 'survey-bad',  value: 1  },
        { icon: 'survey-good',  value: 2  },
        { icon: 'survey-very-good', value: 3 }
      ];
    }
    else {
      this.emojis = [
        { icon: 'survey-very-bad',  value: 1  },
        { icon: 'survey-bad',  value: 2  },
        { icon: 'survey-normal',  value: 3  },
        { icon: 'survey-good',  value: 4  },
        { icon: 'survey-very-good', value: 5 }
      ];
    }
  }
}
