import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
})
export class CardInfoComponent implements OnInit {

  @Input() textButton: string;
  @Input() text: string;
  @Input() image: string;
  @Output() goTo = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  goToRoute(){
    this.goTo.emit();
  }

}
