/* tslint:disable */
import { Injectable } from '@angular/core';
import { BeDigitalSurveysApiBaseService } from '../be-digital-surveys-api-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { BeDigitalSurveysApiRequestBuilder } from '../be-digital-surveys-api-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Responses } from '../models/responses';
import { ResponsesPage } from '../models/responses-page';

@Injectable({
  providedIn: 'root',
})
export class ResponsesService extends BeDigitalSurveysApiBaseService {
  constructor(config: ConfigService, http: HttpService) {
    super(config, http);
  }

  /**
   * Path part for operation findSurveyResponses
   */
  static readonly FindSurveyResponsesPath = '/surveys/{id}/responses';

  /**
   * Find survey responses.
   *
   * Find survey responses.
   *
   * This method provides customization options.
   * To access only the response body, use `findSurveyResponses()` instead.
   *
   * This method doesn't expect any request body.
   */
  findSurveyResponses$Response(params: {
    /**
     * Survey id.
     */
    id: number;
  }): Observable<ResponsesPage> {
    const req = new BeDigitalSurveysApiRequestBuilder(
      ResponsesService.FindSurveyResponsesPath,
      'get'
    );
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json',
    });

    return this.http
      .request<any>(sendReq.method, sendReq.url, sendReq.body, sendReq.options)
      .pipe(
        map((r) => {
          return r as ResponsesPage;
        })
      );
  }

  /**
   * Find survey responses.
   *
   * Find survey responses.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `findSurveyResponses$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  findSurveyResponses(params: {
    /**
     * Survey id.
     */
    id: number;
  }): Observable<ResponsesPage> {
    return this.findSurveyResponses$Response(params).pipe(
      map((r) => r as ResponsesPage)
    );
  }

  /**
   * Path part for operation createResponse
   */
  static readonly CreateResponsePath = '/surveys/{id}/responses';

  /**
   * Create survey response.
   *
   * Create survey response.
   *
   * This method provides customization options.
   * To access only the response body, use `createResponse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createResponse$Response(params: {
    /**
     * Survey id.
     */
    id: number;

    /**
     * Response.
     */
    body: Responses;
  }): Observable<Responses> {
    const req = new BeDigitalSurveysApiRequestBuilder(
      ResponsesService.CreateResponsePath,
      'post'
    );
    if (params) {
      req.path('id', params.id, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json',
    });
    return this.http
      .request<any>(sendReq.method, sendReq.url, sendReq.body, sendReq.options)
      .pipe(
        map((r) => {
          return r as Responses;
        })
      ) 
  }

  /**
   * Create survey response.
   *
   * Create survey response.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `createResponse$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createResponse(params: {
    /**
     * Survey id.
     */
    id: number;

    /**
     * Response.
     */
    body: Responses;
  }): Observable<Responses> {
    return this.createResponse$Response(params).pipe(
      map((r) => r as Responses)
    );
  }
}
