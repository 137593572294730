import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractInitials'
})
export class ExtractInitialsPipe implements PipeTransform {

  /**
   * Use regex to select the initials of name and surname, if more surnames are present it takes only the initial of last one
   */
  transform(nameAndSurname: string): string {
    const initials = nameAndSurname.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }
}
