<div class="ion-padding">
  <div class="nav-tab-container">
    <i class="icon-arrow-left"></i>
    <div
      class="nav-tab-item-skin"
      *ngFor="let question of questionList; let checkedQuestion = first"
      (click)="showQuestion(question.text)"
    >
      <!-- checked CLASS FOR DARK BLUE STYLE -->
      <div class="nav-tab-item" [ngClass]="{ checked: checkedQuestion }"></div>
    </div>
    <i class="icon-arrow-right"></i>
  </div>
  <div class="question-container ion-padding">
    <div class="question-text">
      {{ questionText }}
    </div>

    <!-- FORM -->
    <form>
      <!-- BUTTONS -->
      <div class="answer-buttons">
        <!-- TWO BUTTONS -->
        <div class="form-block">
          <div class="d-flex ion-justify-content-evenly">
            <div class="fancy-check-button" *ngFor="let button of buttonTwo">
              <input type="radio" name="status" id="ok" value="ok" />
              <div class="fancy-check-mark">
                <i [ngClass]="'icon-' + button.icon"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- THREE BUTTONS -->
        <div class="form-block">
          <div class="d-flex ion-justify-content-evenly">
            <div class="fancy-check-button" *ngFor="let button of buttonThree">
              <input
                type="radio"
                name="status"
                id="{{ button.icon }}"
                value="{{ button.icon }}"
              />
              <div class="fancy-check-mark">
                <i [ngClass]="'icon-' + button.icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TEXTAREA -->
      <div class="answer-textarea">
        <ion-textarea
          rows="6"
          placeholder="Inserte aquí su respuesta"
        ></ion-textarea>
      </div>

      <!-- RANGE -->
      <div class="answer-range">
        <p>
          Valora marcando de 1 a 5 siendo 1 el peor valor y 5 el mejor valor
        </p>
        <ion-range min="0" max="5" step="1" snaps="true" ticks="true" pin="true">
          <ion-label slot="start">0</ion-label>
          <ion-label slot="end">5</ion-label>
        </ion-range>
      </div>
    </form>
  </div>
</div>

