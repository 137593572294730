import { Pipe, PipeTransform } from '@angular/core';
import { DateUtilsService } from '@core/services/utils/date-utils.service';

@Pipe({
  name: 'dataText'
})
export class DataTextPipe implements PipeTransform {

  constructor(private readonly dateUtilsService: DateUtilsService) {
  }

  transform(value: string): string {
    const getWeekday = (date: Date) => this.dateUtilsService.getDayArray()[date.getDay()];
    const getMonthName = (date: Date): string => this.dateUtilsService.getMonthArray()[date.getMonth()];
    const localDate = new Date(value);

    return `${ getWeekday(localDate) } ${ localDate.getDate() } de ${ getMonthName(localDate) }`;
  }
}
