<div class="ion-padding modal-content-survey">
  <ion-fab
    vertical="top"
    horizontal="end"
    (click)="dismissModal()"
  >
  </ion-fab>
  <div class="max-width-control">
    <div class="card-illustration" *ngIf="data.image">
      <img [src]="data.image" alt="" />
    </div>
    <div [ngClass]="{'card-text-exit': data.exit , 'card-text':  !data.exit}" *ngIf="data.text">
      {{data.text | translate}}
    </div>
    <div class="card-subtext" *ngIf="data.subText">
        {{data.subText | translate}}
    </div>
    <div class="btn-block" *ngIf="!data.exit">
      <ion-button color="primary"  shape="round" fill="outline" *ngIf="data.firstButton" (click)="dismissModal(!!data.firstButton.action)">{{data.firstButton.text | translate}}</ion-button>
      <ion-button color="primary"  shape="round" *ngIf="data.secondButton" (click)="dismissModal(data.secondButton.action ? true : false)">{{data.secondButton.text | translate}}</ion-button>
    </div>
    <div class="btn-block"  *ngIf="data.exit">
      <ion-button shape="round" expand="block" fill="outline" (click)="dismissModal(!!data.firstButton.action)">{{data.firstButton.text | translate}}</ion-button>
      <ion-button shape="round" expand="block" (click)="dismissModal(data.secondButton.action ? true : false)">{{data.secondButton.text | translate}}</ion-button>
    </div>
  </div>
</div>
