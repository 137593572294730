import { Injectable } from '@angular/core';
import { ConfigService } from '@core/services/config/config.service';
import { HttpService } from '@core/http/http.service';
import { BeDigitalSurveysApiBaseService } from '@core/api/BE-DIGITAL-SURVEYS-API/be-digital-surveys-api-base-service';
import { Observable } from 'rxjs';
import { Home } from '@views/home/home.view';
import { BeDigitalSurveysApiRequestBuilder } from '@core/api/BE-DIGITAL-SURVEYS-API/be-digital-surveys-api-request-builder';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends BeDigitalSurveysApiBaseService {

  constructor(config: ConfigService, http: HttpService) {
    super(config, http);
  }

  static readonly employeePath = '/employee';

  getUser(): Observable<Home> {
    const req = new BeDigitalSurveysApiRequestBuilder(
      EmployeeService.employeePath,
      'get'
    );

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json',
    });

    return this.http
      .request<Home>(sendReq.method, sendReq.url, sendReq.body, sendReq.options)
      .pipe(
        map((r) => {
          return r as Home;
        })
      );
  }
}
