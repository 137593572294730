import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';
import { SpacesHelperService } from '@core/services/spaces/spaces.service';
import { StateHandlerService } from '@core/services/state-handler/state-handler.service';
import { take } from 'rxjs/operators';
import { EmployeeService } from '@core/api/BE-DIGITAL-SURVEYS-API/services/employee.service';
import { ReservationResponseList } from '@core/api/SWAGGER-SPACES-DEFINITIONS/models/reservation-response-list';
import { IonicSafeString, ToastController } from '@ionic/angular';
import { TranslationsService } from '@core/services/translations/translations.service';

@Component({
  selector: 'app-msg-success',
  templateUrl: './msg-success.component.html',
  styleUrls: ['./msg-success.component.scss']
})
export class MsgSuccessComponent implements OnInit {
  @Input() successData: ReservationResponseList = null;
  messageConf = {
    img: 'assets/img/illustrations/ok.png',
    confirmationText: this.translationsService.instant('SUCCESS_MSG.CONFIRMATION'),
    text1: this.translationsService.instant('SUCCESS_MSG.TXT1'),
    text2: this.translationsService.instant('SUCCESS_MSG.TXT2')
  };

  constructor(
    private readonly router: Router,
    private readonly employeeService: EmployeeService,
    private readonly spacesHelperService: SpacesHelperService,
    private readonly stateHandlerService: StateHandlerService,
    private readonly translationsService: TranslationsService,
    private readonly toastController: ToastController
  ) {
  }

  ngOnInit(): void {
    if (this.successData.notAssigned.length) {
      this.showParkingNotAvailableNotification();
      this.messageConf = {
        img: 'assets/img/illustrations/alert-error.png',
        confirmationText: this.translationsService.instant('SUCCESS_MSG.CONFIRMATION_KO'),
        text1: this.translationsService.instant('SUCCESS_MSG.TXT1_KO'),
        text2: this.translationsService.instant('SUCCESS_MSG.TXT2_KO')
      };
    }
  }

  async goToSpaces() {
    await this.spacesHelperService.emptySpaces();
    this.employeeService
      .getUser()
      .pipe(take(1))
      .subscribe(user => {
        this.stateHandlerService.set('spaces', user.spaces);
        this.router.navigate([AppUrls.AppSpaces], { replaceUrl: true });
      });
  }

  private async showParkingNotAvailableNotification(): Promise<void> {
    const toast = await this.toastController.create({
      message: new IonicSafeString(`<b>${ this.translationsService.instant('SUCCESS_MSG.NOTE') }</b>${ this.translationsService.instant('SUCCESS_MSG.NO_PLACE_NOTIFICATION') }`),
      duration: 4000,
      cssClass: 'no-parking-toast'
    });

    await toast.present();
  }
}
