import { Injectable } from '@angular/core';
import { Endpoint } from '@core/models/endpoint/endpoint.interface';
import { HttpRequest } from '@angular/common/http';
import { ConfigService } from '@core/services/config/config.service';
import { Config } from '@core/models/envConfig/config.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
/**
 * Commonly used utils and helpers
 */
export class UtilsService {
  private readonly envApis;
  private envConfig: Config | any;
  private separators = {
    dash: '-',
    slash: '/',
    bracket: '{',
    closeBracket: '}',
    doubleSlash: '//',
    slashAndBracket: '/{',
    closeSlashAndBracket: '}/'
  };
  from: string;

  constructor(
    private readonly config: ConfigService,
    private translateService: TranslateService
  ) {
    this.envConfig = this.config.config;
    this.envApis = this.envConfig.app.rest;
  }

  getTodayDate(): string {
    const today = new Date();
    const month = `${ today.getMonth() + 1 }`.length < 2 ? `0${ today.getMonth() + 1 }` : `${ today.getMonth() + 1 }`;
    const day = `${ today.getDate() }`.length < 2 ? `0${ today.getDate() }` : `${ today.getDate() }`;

    return `${ today.getFullYear() }-${ month }-${ day }`;
  }

  extractDate(dateInput: string) {
    const dateSplitted = dateInput.split('-');
    const tempDate = new Date(
      parseInt(dateSplitted[0], 10),
      parseInt(dateSplitted[1], 10) - 1,
      parseInt(dateSplitted[2], 10)
    );
    const monthsArray = [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER'
    ];
    const daysArray = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ];
    const translatedMonths = monthsArray.map((month) => {
      return this.translateService.instant(`COMMON.MONTH.${ month }`);
    });
    const translatedDays = daysArray.map((day) => {
      return this.translateService.instant(`COMMON.DAY.${ day }`);
    });

    const dayOfWeek = (date) => translatedDays[date.getDay()];
    const getMonthName = (date) => translatedMonths[date.getMonth()];

    return `${ dayOfWeek(tempDate) } ${ tempDate.getDate() } de ${ getMonthName(tempDate) } ${ tempDate.getFullYear() }`;
  }

  /**
   * Helper method for matching a request with its endpoint config block
   * @param request intercepted
   */
  matchRequestEndpoint = (request: HttpRequest<any>) => {
    const requestUrl = this.cleanRequestUrl(request.url);
    const api = this.matchApi(requestUrl, this.envApis);
    const url = {
      baseUrl: api ? api.baseUrl : ''
    };
    const endpoints: Endpoint = api ? api.endpoints : {};
    let endpoint: string;
    for (endpoint in endpoints) {
      if (this.requestUrlComparer(endpoint, endpoints, url.baseUrl, requestUrl)) {
        return { ...endpoints[endpoint], ...url };
      }
    }
    return false;
  }

  /**
   * Helper method for retrieving required config for interceptors
   * @param endpoint contains specific endpoint info and config
   * @param interceptorName (Loader, HttpCustom and Retry)
   */
  getInterceptorConfig = (endpoint, interceptorName: string) => {
    const interceptorOptionsField = {
      Loader: 'loaderOptions',
      HttpCustom: 'restOptions',
      Retry: 'retryOptions'
    };
    // In case somebody wants to extend the HttpCustom interceptor capabilities, you can pass
    // more options by combining the httpCustomAddons object with the block configuration
    const httpCustomAddons = {
      customProp: ''
    };
    return interceptorName === 'HttpCustom' ? { ...httpCustomAddons, ...endpoint[interceptorOptionsField[interceptorName]] }
      : endpoint[interceptorOptionsField[interceptorName]];
  }

  /**
   * Returns provided value as Int number
   * @param value: any
   */
  toInteger = (value: any): number => {
    return parseInt(`${ value }`, 10);
  }

  normalizeBoolean = (value) => {
    return value === 'true';
  }

  /**
   * Checks the provided endpoint name containing variables to find out if the
   * request url matches it
   *
   * @param isVariable: boolean
   * @param endpUrl: string
   * @param baseUrl: string
   * @param reqUrl: string
   */
  private checkVariableEndpoints = (
    isVariable: boolean,
    endpUrl: string,
    baseUrl: string,
    reqUrl: string
  ): boolean => {
    if (!isVariable) {
      return false;
    }

    // First split the urls into chunks
    // Special handling for urls starting with 'protocol://' <- we need to remove that part
    // but considering that it may be the baseUrl
    const endpUrlChunks = this.urlChunks(endpUrl, baseUrl);
    const reqUrlChunks = this.urlChunks(reqUrl, baseUrl);

    // If they're not the same length they're not a match
    if (endpUrlChunks.length !== reqUrlChunks.length) {
      return false;
    }

    // Now check how many fields and variables the url contains and their positions
    // and try to determine if the request url satisfies them
    // The main idea will be that 'some/{variable}/url/provided' will match 'some/doe/url/provided'
    // by comparing them removing the variable part and separators -> 'someurlprovided' === 'someurlprovided'
    while (endpUrlChunks.some(this.isVariable)) {
      const index = endpUrlChunks.findIndex(this.isVariable);
      endpUrlChunks.splice(index, 1);
      reqUrlChunks.splice(index, 1);
    }

    return endpUrlChunks.join('') === reqUrlChunks.join('') ||
      (baseUrl + endpUrlChunks.join('') === reqUrlChunks.join(''));
  }

  /**
   * Removes possible parameters from request url so as to avoid issues on endpoint detection
   * @param requestUrl: string representing the original request url
   * @returns string: filtered url
   */
  private cleanRequestUrl = (requestUrl: string) => {
    const param = '?';
    return requestUrl.indexOf(param) !== -1 ? requestUrl.slice(0, requestUrl.indexOf(param)) : requestUrl;
  }

  /**
   * Returns endpoint check result against provided request url
   * @param endpoint: string
   * @param endpoints: Endpoint
   * @param baseUrl: string
   * @param requestUrl: string
   * @param variableEndpoint (optional): boolean
   */
  private compare = (endpoint: string, endpoints: Endpoint, baseUrl: string, requestUrl: string, variableEndpoint?: boolean) => {
    return endpoint ? endpoints[endpoint].url === requestUrl ||
      (baseUrl + endpoints[endpoint].url) === requestUrl ||
      requestUrl.indexOf(endpoints[endpoint].url) !== -1 ||
      requestUrl.indexOf(baseUrl + endpoints[endpoint].url) !== -1 ||
      this.checkVariableEndpoints(variableEndpoint, endpoints[endpoint].url, baseUrl, requestUrl) :
      false;
  }

  /**
   * Checks if provided string contains interpretable variables
   * @param fragment: string
   */
  private isVariable = (fragment: string): boolean => {
    const ind = '{';
    return fragment.indexOf(ind) !== -1;
  }

  /**
   * Searches for the api block containing the request endpoint and returns it
   * @param requestUrl: string
   * @param envApis: EnvRest api blocks
   * @return api: identified api block
   */
  private matchApi = (requestUrl: string, envApis) => {
    let api: string;
    const apis = envApis;
    for (api in apis) {
      if (
        this.requestUrlComparer(
          '',
          apis[api].endpoints,
          apis[api].baseUrl,
          requestUrl
        )
      ) {
        return apis[api];
      }
    }
    return false;
  }

  /**
   * Compares the request url against the available endpoints to find a match
   * @param endpoint: string
   * @param endpoints: Endpoint
   * @param baseUrl: string
   * @param requestUrl: string
   * @param variableEndpoint (optional): boolean
   */
  private requestUrlComparer = (
    endpoint: string,
    endpoints: Endpoint,
    baseUrl: string,
    requestUrl: string,
    variableEndpoint?: boolean
  ) => {
    if (!endpoints || !Object.keys(endpoints).length) {
      return false;
    }
    // If no endpoint is provided means we're still looking for the api block, so we'll iterate through
    // all the endpoints for each api block until finding it
    if (!endpoint) {
      let endp: string;
      for (endp in endpoints) {
        if (
          this.requestUrlComparer(
            endp,
            endpoints,
            baseUrl,
            requestUrl,
            this.isVariable(endp)
          )
        ) {
          endpoint = endp;
          break;
        }
      }
    }
    return this.compare(endpoint, endpoints, baseUrl, requestUrl, variableEndpoint);
  }

  /**
   * Removes protocol part and returns the rest of the url as chunks
   * considering the char '/' as separator
   *
   * @param url: string
   * @param baseUrl (optional): string. Pending implementation
   */
  private urlChunks = (url: string, baseUrl?: string) => {
    return url.indexOf(this.separators.doubleSlash) !== -1 ?
      url.split(this.separators.doubleSlash)[1].split(this.separators.slash) :
      url.split(this.separators.slash);
  }
}
