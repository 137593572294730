 <!-- THREE BUTTONS -->
 <div class="form-block">
  <div class="d-flex ion-justify-content-evenly">
    <div class="fancy-check-button" *ngFor="let emoji of emojis; let i= index">
      <input
        type="radio"
        name="status"
        id="{{i}}"
        [checked]="emoji.checked"
        [value]= "emoji.value"
        (change)= "nextEmoj($event,emoji.icon)"
      />
      <div class="fancy-check-mark">
        <i [ngClass]="'icon-' + emoji.icon"></i>
      </div>
    </div>
  </div>
</div>
