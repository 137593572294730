<div class="calendar-form-block form-block">
  <label class="small-grey-label">Fecha</label>
<ng-container [formGroup]="parentForm">
    <input
    placeholder="dd/mm/aaaa"
    [readonly]="readonly"
    autocomplete="off"
    [name]="controlName"
    ngbDatepicker
    #d="ngbDatepicker"
    [footerTemplate]="clear"
    (dateSelect)="onDateSelection($event, controlName)"
    (closed)="closeCalendar(controlName)"
    [formControlName]="controlName"
    [minDate]="minDateSelected"
    [maxDate]="maxDateSelected"
    [markDisabled]="markDisabled"
  />
  <div class="form-container" (click)="d.toggle()">
    <i class="icon-calendar"></i>
  </div>

  <ng-template #clear>
    <div class="row justify-content-center">
      <button
        class="btn btn-rojo btn-sm mb-2"
        *ngIf="deleteBtn"
        [disabled]="deleteDisabled"
        (click)="deleteDate(d)"
      >
        {{ 'INPUT_DATEPICKER.BORRAR' | translate }}
      </button>
    </div>
  </ng-template>
</ng-container>
</div>
