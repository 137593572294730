import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-answer-text',
  templateUrl: './answer-text.component.html',
  styleUrls: ['./answer-text.component.scss'],
})
export class AnswerTextComponent implements OnInit, OnChanges {
  @Input() indexQuestion;
  @Input() question;
  @Input() maxLength;
  @Input() minLength;
  @Input() survey;
  @Output() next: EventEmitter<any> = new EventEmitter();
  textValue: string;
  enabled: boolean;

  constructor(
    private platform: Platform
  ) { }

  ngOnInit() {
    // Check if this question has already been answered and show that previous input if so
    // Enable button accordingly (if it was already ok for submit, it'll still be)
    this.textValue = this.survey.questions[this.indexQuestion].value || '';
    this.enabled = this.textValue.length >= this.minLength && this.textValue.length < this.maxLength;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (const propertyName in changes) {
      // tslint:disable-next-line
      const changedProperty = changes[propertyName];
      if (changedProperty.currentValue.type === 'input') {
        this.textValue = changedProperty.currentValue.value || '';
        this.enabled = this.textValue.length >= this.minLength && this.textValue.length < this.maxLength;
      }
    }
  }

  nextTxt(): void {
    const textTrimmed = this.textValue.trim();

    this.next.emit({ value: textTrimmed });
  }

  /**
   * Update textValue with the text input value
   * @param inputValue: string
   */
  write(inputValue: string): void {
    this.textValue = inputValue;
    if (this.platform.is('android')) {
      this.spacePulled();
    }
    this.enabled = this.textValue.length >= this.minLength && this.textValue.length < this.maxLength;
  }

  /**
   * This method detect when there are multiples spaces in the text input
   */
  spacePulled() {
    // FIXME: there is an edge case where if the user put two consecutive white spaces at the end of the string, it keeps the string clean
    //  but it leaves the two ending spaces untouched, right now it's not a problem because on next event the string will be trimmed
    if (this.textValue.substr(-1) === ' ') {
      this.cleanTextValueFromMultipleSpaces();
    }
  }

  /**
   * This method remove multiple spaces from the text input
   */
  private cleanTextValueFromMultipleSpaces() {
    this.textValue = this.textValue.replace(/\s+/g, ' ');
  }
}
