import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StateHandlerService } from '@core/services/state-handler/state-handler.service';
import { Header } from '../components/header.component';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public isHeaderVisible: boolean = false;
  private infoHeader$: BehaviorSubject<Header> = new BehaviorSubject<Header>({title: ''});
  public called = false;
  private userCompleteName: string;

  constructor(private stateHandlerService: StateHandlerService) {
  }

  /**
   * Get actual title as observable
   */
  getInfoHeaderObservable(): Observable<Header> {
    return this.infoHeader$.asObservable();
  }

  /**
   * Set the new variable for title
   */
  setInfoHeader(newInfo: Header): void {
    this.infoHeader$.next(newInfo);
  }

  /**
   * Get actual logged user
   */
  getActualUser(): Observable<any> {
    this.called = true;
    return this.stateHandlerService.get('loggedUser');
  }

  setName(name: string) {
    this.userCompleteName = name;
  }

  getName(): string {
    return this.userCompleteName;
  }

}
