import { Component, OnInit } from '@angular/core';
import { SpacesHelperService } from '@core/services/spaces/spaces.service';

enum TabSlug {
  Approve = 'approve',
  Pending = 'pending'
}

@Component({
  selector: 'app-nav-tabs-top',
  templateUrl: './nav-tabs-top.component.html',
  styleUrls: ['./nav-tabs-top.component.scss'],
})
export class NavTabsTopComponent implements OnInit {
  public selectTab: any;
  TabSlug = TabSlug;
  public tabList = [
    { text: 'REQUEST.ASSIGNED', icon: 'ok-bold', slug: TabSlug.Approve, isActive: false },
    { text: 'REQUEST.PENDING', icon: 'pending', slug: TabSlug.Pending, isActive: false }
  ];
  private spaces;

  constructor(private spacesHelperService: SpacesHelperService) {
  }

  ngOnInit(): void {
    this.initializeActiveTab();
  }

  switchTab(tab): void {
    this.selectTab = tab;
    this.switchVisibility();
    this.selectTab.isActive = true;
  }

  private async initializeActiveTab(): Promise<void> {
    this.selectTab = this.tabList[1];
    this.spaces = await this.spacesHelperService.getSpaces();
    this.spaces.assigned && this.spaces.assigned.length ? this.setAssignedTabAsActive() : this.setRequestTabAsActive();
  }

  private setAssignedTabAsActive(): void {
    this.selectTab = this.tabList[0];
    this.selectTab.isActive = true;
  }

  private setRequestTabAsActive(): void {
    this.selectTab = this.tabList[1];
    this.selectTab.isActive = true;
  }

  private switchVisibility() {
    this.tabList.map(tab => {
      tab.isActive = false;
    });
  }
}
