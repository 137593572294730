<!-- MAQUETA BDGTAL 1.0 -->
<span class="span-reset d-flex flex-wrap" *ngIf="appFlow ==='f1'">
  <ion-card class="ion-padding request-card" button="false"
            [ngClass]="{'selected': selectRequest }"
            *ngFor="let spaceAssigned of spaceAssigned, let i = index">
    <ion-row class="d-flex ion-justify-content-between ion-align-items-center">
      <div class="request-card-date">
        {{ spaceAssigned.dateExtended }}
      </div>
      <div class="request-card-status desktop" *ngIf="spaceAssigned.type === 'PARKING_PLACE' && spaceAssigned.disabled">
        <i class="icon-accessibility-on"></i>
      </div>
      <div class="request-card-status parking" *ngIf="spaceAssigned.type === 'PARKING_PLACE'">
        <i class="icon-car"></i>
      </div>
      <div class="request-card-status desktop" *ngIf="spaceAssigned.type === 'WORK_PLACE'">
        <i class="icon-monitor"></i>
      </div>
    </ion-row>
    <ion-row>
      <ion-col size="3" class="ion-text-end">
        <div class="request-card-label">
          {{'REQUEST.BUILDING' | translate}}
        </div>
      </ion-col>
      <ion-col class="pl-desktop" size="9">
        <div class="request-card-info">
          {{ spaceAssigned.building}}
        </div>
      </ion-col>
      <ion-col size="3" class="ion-text-end">
        <div class="request-card-label">
          {{'REQUEST.FLOOR' | translate}}
        </div>
      </ion-col>
      <ion-col class="pl-desktop" size="9">
        <div class="request-card-info">
          {{ spaceAssigned.floor }}
        </div>
      </ion-col>
      <ion-col size="3" class="ion-text-end">
        <div class="request-card-label">
          {{'REQUEST.PLACE' | translate}}
        </div>
      </ion-col>
      <ion-col class="pl-desktop" size="9">
        <div class="request-card-info">
          {{ spaceAssigned.name}}
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="d-flex ion-justify-content-between align-items-center">
      <button *ngIf="!spaceAssigned.used"
              (click)="deleteRequest(spaceAssigned)" class="ion-activatable ripple-parent">
        <ion-icon class="icn-delete" name="trash-outline"></ion-icon>
        <ion-ripple-effect></ion-ripple-effect>
        {{ 'BUTTONS.DELETE' | translate }}
      </button>

      <button class="ion-activatable ripple-parent" (click)="readQr(spaceAssigned)" [disabled]="spaceAssigned.used">
        <div *ngIf="!spaceAssigned.used" class="t-grey-light">{{ 'BUTTONS.CHECK_QR' | translate }}</div>
        <i class="icon-qr" [ngClass]="{'enabled': spaceAssigned.used }"></i>
        <ion-ripple-effect *ngIf="!spaceAssigned.used"></ion-ripple-effect>
      </button>
    </ion-row>
  </ion-card>

  <ion-card class="ion-padding request-card" *ngIf="!spaceAssigned">
    <ion-card-content class="big blue ion-text-center" [translate]="'REQUEST.NO_ASSIGNED'"></ion-card-content>
  </ion-card>
</span>

<!-- MAQUETA BDGTAL 2.0 -->
<!-- PUESTO PERMANENTE -->
<span class="span-reset" *ngIf="appFlow ==='f2'">
  <ion-card *ngIf="onlyParkingRequest" class="ion-padding ongoing-spot">
    <ion-row class="d-flex ion-justify-content-between ion-align-items-center">
      <div class="card-title">{{ 'SPACES.PERMANENT' | translate }}</div>
    </ion-row>
    <ion-row>
      <i class="icon-monitor col-icon"></i>
      <ion-col class="ion-no-padding">
        <ion-row>
          <ion-col size="3" class="ion-text-end">
            <div class="ongoing-spot-label">{{ 'ONGOING_SPOT.BUILDING' | translate }}</div>
          </ion-col>
          <ion-col class="pl-desktop" size="9">
            <div class="ongoing-spot-info">
              {{ spacePermanent[0]?.building }}
            </div>
          </ion-col>
          <ion-col size="3" class="ion-text-end">
            <div class="ongoing-spot-label">{{ 'ONGOING_SPOT.FLOOR' | translate }}</div>
          </ion-col>
          <ion-col class="pl-desktop" size="9">
            <div class="ongoing-spot-info">
              {{ spacePermanent[0]?.floor }}
            </div>
          </ion-col>
          <ion-col size="3" class="ion-text-end pl-0">
            <div class="ongoing-spot-label">{{ 'ONGOING_SPOT.PLACE' | translate }}</div>
          </ion-col>
          <ion-col class="pl-desktop" size="9">
            <div class="ongoing-spot-info">
              {{ spacePermanent[0]?.name }}
            </div>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-card>

  <!-- CONTAINER EXTERNO BLANCO -->
  <ion-card class="dropdown-card-container ion-padding" *ngFor="let space_assigned of spaceAssigned; let i = index"
            [ngClass]="openDropdown && dropdownIndex === i ? 'openDropdown' : 'closeDropdown'">
    <!-- FILA SUPERIOR -->
    <ion-row class="ion-justify-content-between ion-align-items-center ion-nowrap" (click)="toggleDropdown(i)">
      <!-- FECHA -->
      <div class="card-title">
        {{ space_assigned.dateExtended }}
      </div>
      <i class="icon-arrow-down"></i>
      <ion-ripple-effect></ion-ripple-effect>
    </ion-row>

    <!-- FILA INFERIOR SOLICITUD CONTRAIDA: ICONOS Y BOTÓN "Solicitar Parking" -->
    <ion-row class="ion-justify-content-between ion-align-items-center action-row">
      <div class="d-flex icons-row">
        <i class="icon-monitor" *ngIf="space_assigned.type === 'WORK_PLACE'"></i>
        <i class="icon-car" *ngIf="space_assigned.parkingplace"></i>
        <i class="icon-accessibility-on" *ngIf="space_assigned.parkingplace?.disabled"></i>
        <ion-icon src="assets/icon/parkingElectric-outline.svg" class="electric-color" *ngIf="space_assigned.parkingplace?.vElectric"></ion-icon>
        <ion-icon name="people-outline" class="shared-color" *ngIf="space_assigned.parkingplace?.vShared"></ion-icon>
      </div>
      <!-- <div class="ion-activatable action-col"
           *ngIf="!space_assigned.parkingplace && space_assigned.type === 'WORK_PLACE' && !isExternEmployee && !isDirector">
        <div (click)="requestParking(space_assigned.date)"
             class="t-primary">{{ 'SPACES.REQUEST_PARKING' | translate }}</div>
        <i class="icon-car"></i>
        <ion-ripple-effect></ion-ripple-effect>
      </div> -->
    </ion-row>

    <!-- FILA INFERIOR SOLICITUD DESPLEGADA: CONTAINER GRIS SOLICITUD -->
    <ion-card *ngIf="!onlyParkingRequest" class="ion-no-margin dropdown-card-drop">
      <ion-row>
        <i class="icon-car col-icon" *ngIf="space_assigned.type === 'PARKING_PLACE'"></i>
        <i class="icon-monitor col-icon" *ngIf="space_assigned.type === 'WORK_PLACE'"></i>
        <ion-col class="ion-no-padding">
          <ion-row>
            <ion-col size="4" class="ion-text-end">
              <div class="request-card-label">
                {{ "REQUEST.BUILDING" | translate }}
              </div>
            </ion-col>
            <ion-col class="pl-desktop" size="8">
              <div class="request-card-info">
                {{ space_assigned.spaceId ? space_assigned.building : '' }}
              </div>
            </ion-col>
            <ion-col size="4" class="ion-text-end">
              <div class="request-card-label">
                {{ "REQUEST.FLOOR" | translate }}
              </div>
            </ion-col>
            <ion-col class="pl-desktop" size="8">
              <div class="request-card-info">
                {{ space_assigned.spaceId ? space_assigned.floor : '' }}
              </div>
            </ion-col>
            <ion-col size="4" class="ion-text-end">
              <div class="request-card-label">
                {{ "REQUEST.PLACE" | translate }}
              </div>
            </ion-col>
            <ion-col class="pl-desktop" size="8">
              <div class="request-card-info">
                {{ space_assigned.spaceId ? space_assigned.name : '' }}
              </div>
            </ion-col>
          </ion-row>
        </ion-col>

      </ion-row>
      <ion-row class="d-flex ion-justify-content-between align-items-center">
        <div>
          <button *ngIf="!space_assigned.used"
                  (click)="deleteRequestF2(space_assigned.type, space_assigned.id)"
                  class="ion-activatable ripple-parent">
            <ion-icon class="icn-delete" name="trash-outline"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
            {{ "BUTTONS.DELETE2" | translate }}
          </button>
        </div>

        <div>
          <button
            class="ion-activatable ripple-parent" (click)="readQr(space_assigned)" [disabled]="space_assigned.used">
            <div *ngIf="!space_assigned.used" class="t-grey-light">
              {{ "BUTTONS.CHECK_QR" | translate }}
            </div>
            <i class="icon-qr" [ngClass]="{ enabled: space_assigned.used }"></i>
            <ion-ripple-effect *ngIf="!space_assigned.used"></ion-ripple-effect>
          </button>
        </div>
      </ion-row>
    </ion-card>
    <!--PARKING: FILA INFERIOR SOLICITUD DESPLEGADA: CONTAINER GRIS SOLICITUD -->
                <ion-card *ngIf="space_assigned.parkingplace" class="ion-no-margin dropdown-card-drop">
                  <ion-row>
                    <i class="icon-car col-icon"></i>
                    <i class="icon-accessibility-on" *ngIf="space_assigned.parkingplace?.disabled"></i>
                    <ion-icon src="assets/icon/parkingElectric-outline.svg" class="electric-color" *ngIf="space_assigned.parkingplace?.vElectric"></ion-icon>
                    <ion-icon name="people-outline" class="shared-color" *ngIf="space_assigned.parkingplace?.vShared"></ion-icon>
                    <ion-col class="ion-no-padding">
                      <ion-row>
                        <ion-col size="4" class="ion-text-end">
                          <div class="request-card-label">
                            {{ "REQUEST.BUILDING" | translate }}
                          </div>
                        </ion-col>
                        <ion-col class="pl-desktop" size="8">
                          <div class="request-card-info">
                            {{ space_assigned.parkingplace.building }}
                          </div>
                        </ion-col>
                        <ion-col size="4" class="ion-text-end">
                          <div class="request-card-label">
                            {{ "REQUEST.FLOOR" | translate }}
                          </div>
                        </ion-col>
                        <ion-col class="pl-desktop" size="8">
                          <div class="request-card-info">
                            {{ space_assigned.parkingplace.floor }}
                          </div>
                        </ion-col>
                        <ion-col size="4" class="ion-text-end">
                          <div class="request-card-label">
                            {{ "REQUEST.PLACE" | translate }}
                          </div>
                        </ion-col>
                        <ion-col class="pl-desktop" size="8">
                          <div class="request-card-info">
                            {{ space_assigned.parkingplace.name }}
                          </div>
                        </ion-col>
                      </ion-row>
                    </ion-col>

                  </ion-row>
                  <ion-row class="d-flex ion-justify-content-between align-items-center">
                    <div>
                      <button *ngIf="!space_assigned.parkingplace.used"
                              (click)="deleteRequestF2(space_assigned.parkingplace.type, space_assigned.id)"
                              class="ion-activatable ripple-parent">
                        <ion-icon class="icn-delete" name="trash-outline"></ion-icon>
                        <ion-ripple-effect></ion-ripple-effect>
                        {{ "BUTTONS.DELETE2" | translate }}
                      </button>
                    </div>
                    <div *ngIf="showParkingQr">
                      <button
                        class="ion-activatable ripple-parent" (click)="readQr(space_assigned)"
                        [disabled]="space_assigned.parkingplace.used">
                        <div *ngIf="!space_assigned.parkingplace.used" class="t-grey-light">
                          {{ "BUTTONS.CHECK_QR" | translate }}
                        </div>
                        <i class="icon-qr" [ngClass]="{ enabled: space_assigned.parkingplace.used }"></i>
                        <ion-ripple-effect *ngIf="!space_assigned.parkingplace.used"></ion-ripple-effect>
                      </button>
                    </div>

                  </ion-row>
                </ion-card>
  </ion-card>
  <ion-card class="ion-padding request-card" *ngIf="!spaceAssigned && !onlyParkingRequest">
      <!-- ILUSTRACIÓN -->
      <div class="card-illustration d-flex ion-justify-content-center">
        <img src="assets/img/illustrations/no-spaces.png" alt=""/>
      </div>
    <!-- TEXT -->
      <ion-card-content class="big ion-text-center" [translate]="'REQUEST.NO_ASSIGNED_2'"></ion-card-content>
  </ion-card>
</span>
