import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-answer-survey',
  templateUrl: './answer-survey.component.html',
  styleUrls: ['./answer-survey.component.scss']
})
export class AnswerSurveyComponent implements OnInit {
  public question: string;
  public questionList = [
    { id: 1, text: 'Pregunta 1', currentQuestion: false},
    { id: 2, text: 'Pregunta 2', currentQuestion: false},
    { id: 3, text: 'Pregunta 3', currentQuestion: false},
    { id: 4, text: 'Pregunta 4', currentQuestion: false},
    { id: 5, text: 'Pregunta 5', currentQuestion: false}
  ];
  public questionText: string = this.questionList[0].text;

  public buttonTwo = [
    { icon: 'survey-ok', checked: true },
    { icon: 'survey-ko', checked: false },
  ];

  public buttonThree = [
    { icon: 'survey-verygood', checked: false },
    { icon: 'survey-good', checked: true },
    { icon: 'survey-bad', checked: false }
  ];

  constructor() { }

  ngOnInit() {}

  showQuestion(question: string) {
    this.questionText = question;
    return this.questionText;
  }

}
