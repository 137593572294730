import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-btn-block-bottom',
  templateUrl: './btn-block-bottom.component.html',
  styleUrls: ['./btn-block-bottom.component.scss'],
})
export class BtnBlockBottomComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() customText: string;
  @Input() secondaryCustomText: string;
  @Input() secondaryButton: boolean = false;
  @Output() action = new EventEmitter();
  @Output() secondaryAction: EventEmitter<any> = new EventEmitter();
  text: string;
  secondaryText: string;

  ngOnInit() {
    this.text = this.customText || 'REQUEST.BUTTON';
    this.secondaryText = this.secondaryCustomText || 'MODAL.RETURN';
  }

  actionButton(){
    this.action.emit();
  }

  secondaryActionButton() {
    this.secondaryAction.emit();
  }
}
