<div class="answer-buttons">
  <!-- TWO BUTTONS -->
  <div class="form-block">
    <div class="d-flex ion-justify-content-evenly">
      <div id="survey-button-ko" class="fancy-check-button">
        <input type="radio" name="status" [checked]="koChecked" [value]="false" (click)="nextBtn($event,'survey-ko')"/>
        <div class="fancy-check-mark">
          <i [ngClass]="appFlow === 'f2' ? 'icon-survey-no' : 'icon-survey-ko'"></i>
        </div>
      </div>
      <div id="survey-button-ok" class="fancy-check-button">
        <input type="radio" name="status" [checked]="okChecked" [value]="true" (click)="nextBtn($event,'survey-ok')"/>
        <div class="fancy-check-mark">
          <i [ngClass]="appFlow === 'f2' ? 'icon-survey-yes' : 'icon-survey-ok'"></i>
        </div>
      </div>
    </div>
  </div>
</div>
