<div class="nav-tab-container ion-padding-horizontal">
  <div class="nav-tab-item-skin" *ngFor="let item of items; let i = index">
    <!-- '.checked' class for dark blue style -->
    <div class="nav-tab-item" [ngClass]="{ checked: item.checked }"></div>
  </div>
</div>

<div class="d-flex ion-justify-content-between px-2">
  <ion-icon name="chevron-back-outline" [ngClass]="{ 'step-move-disabled': !enableBackBtn }" (click)="move(-1)"></ion-icon>
  <div class="step-text">
    <span class="current-step">{{ currentStep }}</span>
    {{ 'SURVEYS.STEPS.OF' | translate }} {{ steps }}
  </div>
  <!-- '.step-move-disabled' class for grey disable style -->
  <ion-icon name="chevron-forward-outline" [ngClass]="{ 'step-move-disabled': !enableNextBtn }" (click)="move(1)"></ion-icon>
</div>
