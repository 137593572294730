import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceResponse'
})
export class SpaceResponsePipe implements PipeTransform {

  transform(value: string, elementType: 'building' | 'floor' | 'space'): string {
    const spaceSplit = value.split('_');
    switch (elementType) {
      case 'building':
        return spaceSplit[0];
      case 'floor':
        return spaceSplit[1];
      case 'space':
        return spaceSplit[2];
    }
  }
}
