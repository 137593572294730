/* tslint:disable */
import { Injectable } from '@angular/core';
import { BeDigitalSurveysApiBaseService } from '../be-digital-surveys-api-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { BeDigitalSurveysApiRequestBuilder } from '../be-digital-surveys-api-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Pageable } from '../models/pageable';
import { Survey } from '../models/survey';
import { SurveyEditableProperties } from '../models/survey-editable-properties';
import { SurveysPage } from '../models/surveys-page';
import { Home } from '@app/+views/home/home.view';

@Injectable({
  providedIn: 'root',
})
export class SurveysService extends BeDigitalSurveysApiBaseService {
  constructor(config: ConfigService, http: HttpService) {
    super(config, http);
  }

  /**
   * Path part for operation findSurveys
   */
  static readonly FindSurveysPath = '/surveys';

  /**
   * Find surveys.
   *
   * Find surveys using multiple parameters.
   *
   * This method provides customization options.
   * To access only the response body, use `findSurveys()` instead.
   *
   * This method doesn't expect any request body.
   */
  findSurveys$Response(params?: {
    /**
     * Active survey filter.
     */
    active?: boolean;

    /**
     * Pagination info.
     */
    pagination?: Pageable;
  }): Observable<SurveysPage> {
    const req = new BeDigitalSurveysApiRequestBuilder(
      SurveysService.FindSurveysPath,
      'get'
    );
    if (params) {
      req.query('active', params.active, {});
      req.query('pagination', params.pagination, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json',
    });

    return this.http
      .request<any>(sendReq.method, sendReq.url, sendReq.body, sendReq.options)
      .pipe(
        map((r) => {
          return r as SurveysPage;
        })
      );
  }

  /**
   * Find surveys.
   *
   * Find surveys using multiple parameters.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `findSurveys$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  findSurveys(params?: {
    /**
     * Active survey filter.
     */
    active?: boolean;

    /**
     * Pagination info.
     */
    pagination?: Pageable;
  }): Observable<SurveysPage> {
    return this.findSurveys$Response(params).pipe(map((r) => r as SurveysPage));
  }

  /**
   * Path part for operation createSurvey
   */
  static readonly CreateSurveyPath = '/surveys';

  /**
   * Create survey.
   *
   * Create survey.
   *
   * This method provides customization options.
   * To access only the response body, use `createSurvey()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSurvey$Response(params: {
    /**
     * Survey.
     */
    body: Survey;
  }): Observable<Survey> {
    const req = new BeDigitalSurveysApiRequestBuilder(
      SurveysService.CreateSurveyPath,
      'post'
    );
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json',
    });

    return this.http
      .request<any>(sendReq.method, sendReq.url, sendReq.body, sendReq.options)
      .pipe(
        map((r) => {
          return r as Survey;
        })
      );
  }

  /**
   * Create survey.
   *
   * Create survey.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `createSurvey$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSurvey(params: {
    /**
     * Survey.
     */
    body: Survey;
  }): Observable<Survey> {
    return this.createSurvey$Response(params).pipe(map((r) => r as Survey));
  }

  /**
   * Path part for operation getSurveyById
   */
  static readonly GetSurveyByIdPath = '/surveys/{id}';

  /**
   * Get survey by id.
   *
   * Get survey by id.
   *
   * This method provides customization options.
   * To access only the response body, use `getSurveyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveyById$Response(params: {
    /**
     * Survey id.
     */
    id: number;
  }): Observable<Survey> {
    const req = new BeDigitalSurveysApiRequestBuilder(
      SurveysService.GetSurveyByIdPath,
      'get'
    );
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json',
    });

    return this.http
      .request<any>(sendReq.method, sendReq.url, sendReq.body, sendReq.options)
      .pipe(
        map((r) => {
          return r as Survey;
        })
      );
  }

  /**
   * Get survey by id.
   *
   * Get survey by id.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getSurveyById$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveyById(params: {
    /**
     * Survey id.
     */
    id: number;
  }): Observable<Survey> {
    return this.getSurveyById$Response(params).pipe(
      map(r => r as Survey)
    );
  }

  /**
   * Path part for operation modifySurveyById
   */
  static readonly ModifySurveyByIdPath = '/surveys/{id}';

  /**
   * modify survey by id.
   *
   * modify survey by id.
   *
   * This method provides customization options.
   * To access only the response body, use `modifySurveyById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifySurveyById$Response(params: {
    /**
     * Survey id.
     */
    id: number;

    /**
     * Survey.
     */
    body: SurveyEditableProperties;
  }): Observable<SurveyEditableProperties> {
    const req = new BeDigitalSurveysApiRequestBuilder(
      SurveysService.ModifySurveyByIdPath,
      'patch'
    );
    if (params) {
      req.path('id', params.id, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json',
    });

    return this.http
      .request<any>(sendReq.method, sendReq.url, sendReq.body, sendReq.options)
      .pipe(
        map((r) => {
          return r as SurveyEditableProperties;
        })
      );
  }

  /**
   * modify survey by id.
   *
   * modify survey by id.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `modifySurveyById$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifySurveyById(params: {
    /**
     * Survey id.
     */
    id: number;

    /**
     * Survey.
     */
    body: SurveyEditableProperties;
  }): Observable<SurveyEditableProperties> {
    return this.modifySurveyById$Response(params).pipe(
      map((r) => r as SurveyEditableProperties)
    );
  }


  /**
   Sercicio de posisicon global provisional hasta tener el API de espacios cargado
  */

}
