<!-- MENSAJE ÉXITO -->
<div class="msg-success ion-padding">
  <div class="max-width-control">
    <div>
      <!-- ILUSTRACIÓN -->
      <div class="ion-padding-vertical">
        <img src="{{ messageConf.img  }}" alt="" />
      </div>
      <h1 [translate]="messageConf.confirmationText"></h1>
      <h5 class="ion-padding-vertical" [translate]="messageConf.text1"></h5>
      <h5 class="ion-padding-vertical" [translate]="messageConf.text2"></h5>
    </div>

    <ion-card class="reservation-ctn" *ngFor="let reservation of successData.assigned">
      <ion-card-header>
        <ion-card-subtitle class="subtitle" [translate]="'SUCCESS_MSG.PLACE'"></ion-card-subtitle>
        <ion-card-title class="title">{{reservation.day | dataText}}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <ion-icon *ngIf="!reservation.vElectric" name="car-outline"></ion-icon>
              <ion-icon *ngIf="reservation.vElectric" src="assets/icon/parkingElectric-outline.svg" class="electric-color"></ion-icon>
              <ion-icon *ngIf="reservation.vShared" name="people-outline" class="shared-color"></ion-icon>
              <ion-icon *ngIf="reservation.disabled" src="assets/icon/parkingAccessibility-outline.svg" class="accessibility-color"></ion-icon>
            </ion-col>
          </ion-row>
          <ion-row class="info-row">
            <ion-col size="4">
              <p class="header" [translate]="'SUCCESS_MSG.BUILDING'"></p>
              <p class="content">{{ reservation.space | spaceResponse: 'building' }}</p>
            </ion-col>
            <ion-col size="4">
              <p class="header" [translate]="'SUCCESS_MSG.FLOOR'"></p>
              <p class="content">{{ reservation.space | spaceResponse: 'floor' }}</p>
            </ion-col>
            <ion-col size="4">
              <p class="header" [translate]="'SUCCESS_MSG.PLACE'"></p>
              <p class="content">{{ reservation.space | spaceResponse: 'space' }}</p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <ion-card class="failed-reservation-ctn" *ngFor="let reservationFailed of successData.notAssigned">
      <ion-card-header>
        <ion-grid>
          <ion-row>
            <ion-col size="2" class="d-flex align-items-center">
              <ion-icon name="alert-circle-outline"></ion-icon>
            </ion-col>
            <ion-col size="10">
              <ion-card-subtitle class="subtitle" [translate]="'SUCCESS_MSG.NO_PLACE'"></ion-card-subtitle>
              <ion-card-title class="title">{{reservationFailed.day | dataText}}</ion-card-title>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-header>
    </ion-card>

    <ion-button shape="round" xpand="block" color="secondary" shape="round" [translate]="'SUCCESS_MSG.BTN'" (click)="goToSpaces()"></ion-button>
  </div>
</div>
