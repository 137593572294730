import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
// tslint:disable-next-line:max-line-length
import {
  NgbDatepickerI18n,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n } from './languageDatePickerService';
import { NgbDateFRParserFormatter } from './formatDatePickerService';

@Component({
  selector: 'app-input-datepicker',
  templateUrl: './input-datepicker.component.html',
  styleUrls: ['./input-datepicker.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
  ],
})
export class InputDatepickerComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() minDateSelected: any;
  @Input() maxDateSelected: any;
  @Input() markDisabled?: any;
  @Input() startDay?: any;
  @Input() readonly: boolean;
  @Input() deleteBtn = false;
  @Input() inputValue?: string = '';
  @Input() selectedValues;
  @Input() label;
  @Output() dateSelected = new EventEmitter();
  @Output() closed = new EventEmitter();
  public minDate: any;
  public maxDate: any;
  public deleteDisabled = false;

  constructor(
    private i18n: I18n,
    config: NgbDatepickerConfig,
    calendar: NgbCalendar
  ) {
    // Disabled weekend days (saturday & sunday).
    // config.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 6;
    // Navigate between months only with arrows, not select.
    config.navigation = 'arrows';
    // Set language
    this.i18n.language = 'es';
  }

  ngOnInit() {
    this.parentForm.addControl(
      this.controlName,
      new FormControl(
        { value: this.startDay ? this.startDay : '' },
        Validators.required
      )
    );

    /** Set if exist inputvalue */
    if (this.inputValue !== '') {
      this.parentForm
        .get(this.controlName)
        .setValue(this.parseDatePicker(this.inputValue));
    }

    if (this.parentForm.get(this.controlName).disabled) {
      this.deleteDisabled = true;
    }
  }

  onDateSelection(evt, inputSelected) {
    if (inputSelected === 'beginDate') {
      this.minDate = { year: evt.year, month: evt.month, day: evt.day };
      this.dateSelected.emit(this.minDate);
    }
    if (inputSelected === 'endDate') {
      this.maxDate = { year: evt.year, month: evt.month, day: evt.day };
      this.dateSelected.emit(this.maxDate);
    }
  }

  deleteDate(d) {
    d.toggle();
    this.parentForm.controls[this.controlName].setValue(this.startDay);
    this.dateSelected.emit(this.startDay);
  }

  closeCalendar(inputSelected) {
    this.closed.emit(inputSelected);
  }

  parseDatePicker(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return { year: this.toInteger(dateParts[0]), month: null, day: null };
      } else if (
        dateParts.length === 2 &&
        this.isNumber(dateParts[0]) &&
        this.isNumber(dateParts[1])
      ) {
        return {
          year: this.toInteger(dateParts[1]),
          month: this.toInteger(dateParts[0]),
          day: null,
        };
      }
    }
    return null;
  }

  isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }
}
