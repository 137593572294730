import { Injectable } from '@angular/core';
import { StateHandlerService } from '@core/services/state-handler/state-handler.service';
import { Spaces } from '../../../+views/home/home.view';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpacesHelperService {
  private spaces: Spaces;

  constructor(
    private stateHandlerService: StateHandlerService
  ) {
  }

  /**
   * Returns a promise resolving with spaces to minimize subscriptions to StateHandler
   */
  getSpaces(): Promise<any> {
    return new Promise(res => {
      if (this.spaces) {
        res(this.spaces);
        return;
      }
      this.stateHandlerService.get('spaces')
        .pipe(take(1))
        .subscribe(s => {
          this.spaces = s;
          res(this.spaces);
        });
    });
  }

  /**
   * Empties the service of spaces info so as to force it to fetch it again
   */
  emptySpaces(): Promise<any> {
    return new Promise(res => {
      this.spaces = undefined;
      res(true);
    });
  }
}
