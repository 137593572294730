import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionStorageService } from '../storage/session-storage/session-storage.service';

/**
 * Service that stores and provides a key-value collection across the whole app.
 * It should be used to keep the app state and share data between components that
 * are not part of the same module
 *
 */

@Injectable({
  providedIn: 'root'
})
export class StateHandlerService {
  private stateData: Array<any> = [];

  constructor(
        private sessionStore: SessionStorageService
    ) {}

  /**
   * Returns stored data as an observable
   * @param key: string
   */
  get(key: string): Observable<any> {
    this.stateData[key] = this.sessionStore.get(key) && new BehaviorSubject(this.sessionStore.get(key));

    if (!this.stateData[key]) {
      // TODO review possibility of returning an error instead
      this.set(key, '');
    }
    return this.stateData[key].asObservable();
  }

  /**
   * Sets provided data identified by its key
   * @param key: string
   * @param value: any
   */
  set(key: string, value: any) {
    if (!this.stateData[key]) {
      this.stateData[key] = new BehaviorSubject(value);
    }
    this.sessionStore.set(key, value);
    this.stateData[key].next(value);
  }
}
