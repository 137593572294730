import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';
import { CardErrorComponent } from '@app/shared/components/card-error/card-error.component';
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { MiddlewareComponent } from '@app/shell/middleware/middleware.component';

// This module will handle the main app routes, instead of app module (for better decoupling)

const routes: Routes = [
  {
    path: AppUrls.AppHome,
    loadChildren: () => import('@views/home/home.module').then(m => m.HomeViewModule),
    canActivate: [AuthGuard]
  },
  {
    path: AppUrls.AppLogin,
    loadChildren: () => import('@views/login/login.module').then(m => m.LoginModule)
  },
  {
    path: AppUrls.AppSpaces,
    loadChildren: () => import('@views/spaces/spaces.module').then(m => m.SpacesModule),
    canActivate: [AuthGuard]
  },
  {
    path: AppUrls.AppSurvey,
    loadChildren: () => import('@views/survey/survey.module').then(m => m.SurveyViewModule),
    canActivate: [AuthGuard]
  },
  {
    path: AppUrls.AppError,
    component: CardErrorComponent
  },
  {
    path: AppUrls.AppMiddleware,
    component: MiddlewareComponent
  },
  {
    // Now redirect all unknown routes to login instead of home
    path: '**',
    redirectTo: AppUrls.AppLogin,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
