import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';
import { LoaderService } from '../loader/services/loader.service';
import { MiddlewareService } from './middleware.service';

@Component({
  selector: 'app-middleware',
  templateUrl: './middleware.component.html',
  styleUrls: ['./middleware.component.scss'],
})
export class MiddlewareComponent implements OnInit {

  flows: [];

  constructor(
    private loaderService: LoaderService,
    private middlewareServ: MiddlewareService,
    private router: Router
  ) {
    middlewareServ.removeFlowOfStorage();
   }

  ngOnInit() {
    this.flows = this.middlewareServ.getFlows();
  }

  goToFlow(id: string){
    this.middlewareServ.setFlowInStorageById(id);
    this.router.navigate([AppUrls.AppHome], { replaceUrl: true })
      .then(() => {
        this.loaderService.isLoading$.next(false);
      });
  }
}
