import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { MiddlewareService } from '@app/shell/middleware/middleware.service';

@Component({
  selector: 'app-answer-buttons',
  templateUrl: './answer-buttons.component.html',
  styleUrls: ['./answer-buttons.component.scss'],
})
export class AnswerButtonsComponent implements OnInit, OnChanges {
  @Input() indexQuestion;
  @Input() question;
  @Input() survey;
  @Output() next: EventEmitter<any> = new EventEmitter();
  koChecked: boolean;
  okChecked: boolean;
  appFlow: string;

  constructor(private middleware: MiddlewareService) { }

  ngOnInit() {
    this.appFlow = this.middleware.getFlowIdFromStorage();
    // Check if the buttons already have value, and check them accordingly
    this.koChecked = typeof this.survey.questions[this.indexQuestion].value !== 'undefined'
    && !this.survey.questions[this.indexQuestion].value;
    this.okChecked = this.survey.questions[this.indexQuestion].value;
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    for (const propertyName in changes) {
      // tslint:disable-next-line
      const changedProperty = changes[propertyName];
      if (!changedProperty.isFirstChange()) {
        this.updateButtons(changedProperty);
      }
    }
  }

  nextBtn($event, icon): void {
    this.next.emit({value: $event.target.value, respIcon: icon});
    $event.target.checked = false;
  }

  private updateButtons(changedProperty): void {
    this.koChecked = typeof changedProperty.currentValue.value !== 'undefined' &&
      !changedProperty.currentValue.value;
    this.okChecked = changedProperty.currentValue.value;
  }
}
