<ion-app class="scanner-hide">
  <!-- HEADER -->
  <app-header></app-header>
  <!-- ROUTING -->
  <ion-router-outlet animated="true"></ion-router-outlet>
  <!-- LOADER -->
  <app-loader></app-loader>
  <!-- FOOTER -->
  <app-footer></app-footer>
</ion-app>

<div class="scanner-ui">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
      </ion-buttons>
      <ion-buttons slot="primary">
        <ion-button (click)="closeScan()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-title>Escanea el código</ion-title>
    </ion-toolbar>
  </ion-header>
</div>
