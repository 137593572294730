import { Injectable } from '@angular/core';
import { TranslationsService } from '@core/services/translations/translations.service';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  constructor(private readonly translationsService: TranslationsService) {
  }

  getMonthArray(): string[] {
    const monthArray = [
      'COMMON.MONTH.JANUARY',
      'COMMON.MONTH.FEBRUARY',
      'COMMON.MONTH.MARCH',
      'COMMON.MONTH.APRIL',
      'COMMON.MONTH.MAY',
      'COMMON.MONTH.JUNE',
      'COMMON.MONTH.JULY',
      'COMMON.MONTH.AUGUST',
      'COMMON.MONTH.SEPTEMBER',
      'COMMON.MONTH.OCTOBER',
      'COMMON.MONTH.NOVEMBER',
      'COMMON.MONTH.DECEMBER'
    ];

    return this.getArrayTranslated(monthArray);
  }

  getDayArray(): string[] {
    const dayArray = [
      'COMMON.DAY.SUNDAY',
      'COMMON.DAY.MONDAY',
      'COMMON.DAY.TUESDAY',
      'COMMON.DAY.WEDNESDAY',
      'COMMON.DAY.THURSDAY',
      'COMMON.DAY.FRIDAY',
      'COMMON.DAY.SATURDAY'];

    return this.getArrayTranslated(dayArray);
  }

  private getArrayTranslated(dataArray: string[]): string[] {
    const translatedArray = [];

    for (let i = 0; i < dataArray.length; i++) {
      translatedArray.push(this.translationsService.instant(dataArray[i]));
    }
    console.info(translatedArray);

    return translatedArray;
  }
}
