import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RequestsService, ReservationsService, SpacesService } from '@core/api/SWAGGER-SPACES-DEFINITIONS/services';
import { catchError, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SpacesHelperService } from '@core/services/spaces/spaces.service';
import { SpaceType } from '@core/api/SWAGGER-SPACES-DEFINITIONS/models/space-type';
import { IdType } from '@core/api/SWAGGER-SPACES-DEFINITIONS/models/id-type';

@Component({
  selector: 'app-modal-bottom',
  templateUrl: './modal-bottom.component.html',
  styleUrls: ['./modal-bottom.component.scss']
})
export class ModalBottomComponent {
  isSuccess: boolean = false;
  @Input() requestId: IdType;
  @Input() requestType: SpaceType;
  @Input() isRequest: boolean;

  constructor(
    private modalController: ModalController,
    private spacesService: SpacesService,
    private reservationsService: ReservationsService,
    private spacesHelperService: SpacesHelperService
  ) {
  }

  dismissModal() {
    this.modalController.dismiss()
      .then(() => {
      });
  }

  deleteRequest() {
    const deleteFunction: Observable<void> = this.isRequest ?
      this.reservationsService.deleteAssignedSpacesEmployee({ Id: this.requestId }) :
      this.reservationsService.deleteAssignedSpaceEmployeeV2({
        id: this.requestId,
        spaceType: this.requestType
      });

    deleteFunction
      .pipe(
        take(1),
        catchError(err => {
          console.error('Caught error: ', err);
          return throwError(err);
        })
      )
      .subscribe(async _ => {
      }, error => {
      }, () => {
        this.resetSpaces();
        this.showSuccess();
      });
  }

  private async resetSpaces() {
    await this.spacesHelperService.emptySpaces();
  }

  private showSuccess() {
    this.isSuccess = true;
  }
}
