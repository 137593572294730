import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { SessionStorageService } from '@app/core/services/storage/session-storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MiddlewareService {
  private STORAGE_FLOW_KEY = 'BDGtal_flow';
  private STORAGE_USER_EMAIL = 'user_email';
  private ENVIROMENTS = [];

  constructor(
    private confService: ConfigService,
    private sessionStorage: SessionStorageService
  ) {
  }

  getFlows(): any {
    return this.confService.getProperty('app.flows');
  }

  loadPageSelectFlow(): boolean {
    return this.isEnviromentPageSelectFlow() ? this.confService.getProperty('app.pageSelectFlow') : false;
  }

  isEnviromentPageSelectFlow(): boolean {
    const appBaseUrl = this.confService.getProperty('appBaseUrl');
    const env = this.ENVIROMENTS;

    for (const i in env) {
      if (appBaseUrl.indexOf(env[i]) !== -1) {
        return true;
      }
    }

    return false;
  }

  setFlowInStorageByEmail(email: string) {
    if (email) {
      this.sessionStorage.set(this.STORAGE_USER_EMAIL, email);
    }

    const e = email || this.sessionStorage.get(this.STORAGE_USER_EMAIL);
    this.setFlowInStorageById(this.getFlowByDomain(e));
  }

  getFlowIdFromStorage(): string {
    return this.sessionStorage.get(this.STORAGE_FLOW_KEY);
  }

  setFlowInStorageById(id: string) {
    this.sessionStorage.set(this.STORAGE_FLOW_KEY, id);
  }

  removeFlowOfStorage() {
    this.sessionStorage.remove(this.STORAGE_FLOW_KEY);
  }

  getFlowByDomain(email: any): string {
    const flows = this.getFlows();

    // tslint:disable-next-line:forin
    for (const i in flows) {
      for (const j in flows[i].domains) {
        if (email.indexOf(flows[i].domains[j]) !== -1) {
          return flows[i].id;
        }
      }
    }

    return '';
  }

  getBaseUrlGatewayIfNecessary(requestUrl: string): string {
    const flowId =  this.sessionStorage.get(this.STORAGE_FLOW_KEY);
    const flows = this.getFlows();

    // tslint:disable-next-line:forin
    for (const i in flows) {
      for (const j in flows[i].id) {
        if (flows[i].urlFlowActive && flowId.indexOf(flows[i].id) !== -1) {
          return this.confService.getProperty('app.baseUrlGateway') + '/' + flowId;
        }
      }
    }
    return requestUrl;
  }
}
