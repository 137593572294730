<div class="answer-range">
  <p [innerHTML]="message">
  </p>
  <ion-range min="{{question.interval.left.value}}" max="{{question.interval.right.value}}" step="1"
             snaps="true"
             ticks="false"
             pin="true"
             debounce="200"
             (click)="assignRange($event)"
             (ionChange)="assignRange($event)"
             (ionFocus)="changeFocus(true)"
             [value]="rangeValue">
    <ion-label slot="start">{{ minLabel }}</ion-label>
    <ion-label slot="end">{{ maxLabel }}</ion-label>
  </ion-range>
</div>
<app-btn-block-bottom (action)="nextRange()" [customText]="'BUTTONS.ACCEPT'" [disabled]="!enabled">
</app-btn-block-bottom>
