import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '@environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

// Core
import { CoreModule } from '@core/core.module';

// Shell
import { ShellModule } from '@shell/shell.module';

// Ionic
import { IonicArqModule } from '@app/ionic.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule.forRoot(),
    IonicArqModule.forRoot(),
    ServiceWorkerModule.register(
      'ngsw-worker.js',
      { enabled: environment.production }
    ),
    ShellModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
