<div class="answer-textarea">
  <ion-textarea
    #textArea
    rows="4"
    placeholder="{{ 'SURVEYS.INPUT_TEXT_PLACEHOLDER' | translate }}"
    [maxlength]="maxLength"
    (keyup)="write(textArea.value)"
    [(ngModel)]="textValue"
    (keydown.space)="spacePulled()"
  ></ion-textarea>
</div>
<app-btn-block-bottom (action)="nextTxt()" [customText]="'BUTTONS.ACCEPT'" [disabled]="!enabled">
</app-btn-block-bottom>
