<!-- HEADER -->
<ion-header *ngIf="headerService.isHeaderVisible" class="header-responsive">
  <ion-toolbar class="ion-padding">
    <ion-row class="ion-justify-content-between ion-align-items-center">
      <div class="logo-container">
        <img class="img-fluid" src="assets/img/be-digital-logo.png"  />
      </div>
      <div class="title">
        <span>{{ 'HEADER.TITLE' | translate }}</span>
        <span [innerHTML]="infoHeader.title | translate" class="pl-2"></span>
      </div>
      <i class="icon-log-out" (click)="exit()"></i>
    </ion-row>
    
    <ion-row class="greeting-row" *ngIf="!infoHeader.nameSurvey">
      <ion-avatar class="d-flex ion-justify-content-center ion-align-items-center" >
        <img src="assets/img/profile.svg"  />
        <div class="avatar-acronym">
          {{ userInitials }}
        </div>
      </ion-avatar>
      <div class="greeting-container">
        <div>{{ 'HEADER.HELLO' | translate }}</div>
        <div class="bold capitalize">{{ userCompleteName }}</div>
      </div>
    </ion-row>


    <!-- SURVEY NAME -->
    <ion-row class="surveys-name-container pt-2" *ngIf="infoHeader.nameSurvey">
      <!-- BACK BUTTON -->
      <ion-back-button mode="ios" text="" icon="arrow-back-outline" color="tertiary" defaultHref="home"></ion-back-button>
      <div class="margin-correction pl-2">
        <div class="survey-name">
          {{infoHeader.nameSurvey }}
        </div>
        <div>
          {{infoHeader.dateOpen | date :'d MMM yy'}} / {{infoHeader.dateClose | date :'d MMM yy' }}
        </div>
      </div>
    </ion-row>
  </ion-toolbar>

</ion-header >

<!-- HEADER DESKTOP -->
<header class="header-desktop" *ngIf="infoHeader.headerDesktopVisible" >
  <div class="ion-padding ion-justify-content-between ion-align-items-center d-flex">
    <div class="logo-container">
      <img class="img-fluid" src="assets/img/be-digital-logo.png"  />
    </div>
    <!-- TITLE -->
    <div class="title">
      <span>{{ 'HEADER.TITLE' | translate }} </span>
      <span [innerHTML]="infoHeader.title | translate"></span>
    </div>
    <!-- AVATAR HEADER -->
    <div class="header-avatar-desktop">
      <!-- TOOLTIP CLICK -->
      <ion-avatar class="d-flex ion-justify-content-center ion-align-items-center" (click)="tooltip = !tooltip">
        <img src="assets/img/profile.svg"/>
        <div class="avatar-acronym">
          {{ userInitials }}
        </div>
      </ion-avatar>
      <!-- TOOLTIP: show / hide -->
      <div [ngClass]="{'show': tooltip, 'hide': !tooltip}" class="tooltip-logout">
        <div class="tooltip-text d-flex" (click)="exit()">
          <i class="icon-arrow-left"></i>
          <div>{{ 'HEADER.LOG_OUT' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-md top-margin-correction">
    <!-- SURVEY NAME -->
    <ion-row class="surveys-name-container pt-2" *ngIf="infoHeader.nameSurvey">
      <!-- BACK BUTTON -->
      <ion-back-button mode="ios" text="" icon="arrow-back-outline" color="tertiary" defaultHref="home"></ion-back-button>
      <div class="margin-correction pl-3">
        <div class="survey-name">
          {{infoHeader.nameSurvey }}
        </div>
        <div>
          {{infoHeader.dateOpen | date :'d MMMM yyyy'}} / {{infoHeader.dateClose | date :'d MMMM yyyy' }}
        </div>
      </div>
    </ion-row>
  </div>
</header>
