import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Assigned, Spaces } from '@views/home/home.view';
import { SpacesHelperService } from '@core/services/spaces/spaces.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';
import { ModalBottomComponent } from '@shared/components/modal-bottom/modal-bottom.component';
import { take, takeUntil } from 'rxjs/operators';
import { StateHandlerService } from '@core/services/state-handler/state-handler.service';
import { Router } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';
import { EmployeeService } from '@app/core/api/BE-DIGITAL-SURVEYS-API/services';
import { MiddlewareService } from '@app/shell/middleware/middleware.service';
import { SessionStorageService } from '@app/core/services/storage/session-storage/session-storage.service';
import { ConfigService } from '@app/core/services/config/config.service';
import { Subject } from 'rxjs';
import { EMPLOYEE_KEY, SPACES_KEY } from '@shared/constants/storage/storage';

export enum SpaceType {
  WorkPlace = 'WORK_PLACE',
  ParkingPlace = 'PARKING_PLACE'
}

@Component({
  selector: 'app-card-request',
  templateUrl: './card-request.component.html',
  styleUrls: ['./card-request.component.scss']
})
export class CardRequestComponent implements OnInit, OnDestroy {
  appFlow: string;
  selectRequest = false;
  spaces: Spaces;
  spaceAssigned: Assigned[];
  spacePermanent = [];
  SpaceType: SpaceType;
  qrEnabled = false;
  openDropdown = false;
  onlyParkingRequest = false;
  dropdownIndex: number;
  isExternEmployee: boolean;
  isDirector: boolean;
  surveyDone: boolean;
  showParkingQr: boolean = true;

  @Output() showSurveyEvent: EventEmitter<boolean> = new EventEmitter();
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(
    private readonly spacesHelperService: SpacesHelperService,
    private readonly utilsService: UtilsService,
    private readonly middleware: MiddlewareService,
    private readonly modalController: ModalController,
    private readonly employeeService: EmployeeService,
    private readonly stateHandlerService: StateHandlerService,
    private readonly router: Router,
    private readonly utilsSvc: UtilsService,
    private readonly SessionStorageSvc: SessionStorageService,
    private readonly config: ConfigService) {
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async ngOnInit() {
    this.showParkingQr = this.config.config.app.features.showQrParkingButton;
    this.stateHandlerService.get('onlyParkingRequest').pipe(take(1)).subscribe(s => {
      this.onlyParkingRequest = s;
      this.refreshEmployee();
    });
    this.appFlow = this.middleware.getFlowIdFromStorage();
    this.appFlow === 'f2' && this.getSurveyState();
    this.getTypeEmployee();
    this.deleteF2FromSurvey();
  }

  getTypeEmployee() {
    this.stateHandlerService.get('isExternEmployee').pipe(take(1)).subscribe(res => {
      this.isExternEmployee = res;
    });

    this.stateHandlerService.get('isDirector').pipe(take(1)).subscribe(res => {
      this.isDirector = res;
    });
  }

  getSurveyState() {
    this.stateHandlerService.get('showSurvey').pipe(take(1)).subscribe(res => {
      this.surveyDone = !res;
    });
  }

  readQr(space: Assigned) {
    this.stateHandlerService.set('spaceQr', space);

    if (this.surveyDone) {
      if (space.date !== this.utilsService.getTodayDate()) {
        return;
      }

      this.router.navigate([`${ AppUrls.AppSpaces }/${ AppUrls.AppQr }`], { replaceUrl: true });
      // this.qrEnabled = !this.qrEnabled;
    } else {
      this.utilsSvc.from = 'qrSpace';
      this.showSurveyEvent.emit(true);
    }
  }

  toggleDropdown(index: number) {
    this.dropdownIndex = index;
    this.openDropdown = !this.openDropdown;
  }

  requestParking(date: string) {
    this.stateHandlerService.set('calendarType', SpaceType.ParkingPlace);
    this.stateHandlerService.set('selectedDates', [date]);
    if (this.surveyDone) {
      this.router.navigate([`${ AppUrls.AppSpaces }/${ AppUrls.AppRequestSpace }`], { replaceUrl: true });
    } else {
      this.utilsSvc.from = 'requestParkingSpace';
      this.showSurveyEvent.emit(true);
    }
  }

  async deleteRequest(spaceAssigned: Assigned) {
    const deleteModal = await this.modalController.create({
      component: ModalBottomComponent,
      initialBreakpoint: 0.5,
      breakpoints: [0, 1],
      cssClass: 'modal-delete-space',
      componentProps: {
        requestId: spaceAssigned.id,
        requestType: spaceAssigned.type,
        isRequest: false
      }
    });
    deleteModal.onWillDismiss().then(_ => {
      this.refreshEmployee();
    });

    return await deleteModal.present();
  }

  async deleteRequestF2(type: string, id: number) {
    if (this.surveyDone) {
      const deleteModal = await this.modalController.create({
        component: ModalBottomComponent,
        initialBreakpoint: 0.5,
        breakpoints: [0, 1],
        componentProps: {
          requestId: id,
          requestType: type,
          isRequest: false
        }
      });
      deleteModal.onWillDismiss().then(() => {
        this.refreshEmployee();
      });
      this.SessionStorageSvc.remove('fromDelete');
      return await deleteModal.present();
    } else {
      this.utilsSvc.from = 'deletedSpace';
      this.stateHandlerService.set('fromDelete', { type, id });
      this.showSurveyEvent.emit(true);
    }
  }

  deleteF2FromSurvey() {
    if (this.utilsSvc.from === 'deletedSpace') {
      this.stateHandlerService.get('fromDelete').subscribe(requestToDelete => {
        this.deleteRequestF2(requestToDelete.type, requestToDelete.id);
      });
    }
  }

  private parseSpacesRequested() {
    this.spacePermanent = this.onlyParkingRequest ? this.spaces.permanent : null;

    if (this.spaceAssigned) {
      this.spaceAssigned.map(spaceAssigned => {
        spaceAssigned.dateExtended = spaceAssigned.date ? this.utilsService.extractDate(spaceAssigned.date) : '';
      });
    }
  }

  private checkIfRequestedIsNotEmpty() {
    if (this.spaces.assigned && this.spaces.assigned.length > 0) {
      this.spaceAssigned = this.spaces.assigned;
    } else {
      this.spaceAssigned = null;
    }

    this.parseSpacesRequested();
  }

  private async checkReservationList() {
    this.spaces = await this.spacesHelperService.getSpaces();
    this.toggleDropdown(0);
    this.checkIfRequestedIsNotEmpty();
  }

  private refreshEmployee() {
    this.employeeService.getUser()
      .pipe(
        take(1))
      .subscribe(employee => {
        this.spacesHelperService.emptySpaces();
        this.stateHandlerService.set(SPACES_KEY, employee.spaces);
        this.stateHandlerService.set(EMPLOYEE_KEY, employee);
        this.checkReservationList();
      });
  }
}
