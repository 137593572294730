import { Injectable } from '@angular/core';
import { ConfigService } from '@core/services/config/config.service';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { StorageService } from '@core/services/storage/storage.service';

import { OAuth2AuthenticateOptions, OAuth2Client } from '@byteowls/capacitor-oauth2';
import { Router } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';
import { Browser } from '@capacitor/browser';

const ACCESS_TOKEN: string = 'ACCESS_TOKEN';
const REFRESH_TOKEN: string = 'REFRESH_TOKEN';
const PROVIDER_IN_USE: string = 'OAUTH_2_PROVIDER';

export interface HybridResponse {
  access_token_response: AccessTokenResponse;
}

export interface AccessTokenResponse {
  access_token: string;
  expires_in: number;
  ext_expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

@Injectable()
export class AuthService {
  isLoggedIn$ = new BehaviorSubject(false);
  private oauth2Config: OAuth2AuthenticateOptions;
  private oauth2Provider: string;
  private oauth2ProvidersEmails;
  private oauth2ProvidersEmailsHardCode = {
    azure: [
      '@ciberexperis.es',
      '@appsrh.com',
      '@experis.es'
    ],
    pingFederate: [
      '@aegon.es',
      '@aegon.com',
      '@aegonam.com',
      '@aegonassetmanagement.com'
    ]
  };
  private refreshToken: any;
  private accessToken: any;
  private oauthUserId: any;
  private name: any;

  constructor(
    private config: ConfigService,
    private platform: Platform,
    private storage: StorageService,
    private readonly router: Router
  ) {
    this.oauth2ProvidersEmails = {
      azure: this.config.getProperty('security.providers.azure.authProviders'),
      pingFederate: this.config.getProperty('security.providers.pingFederate.authProviders')
    };
  }

  checkOauth2FormProvider(value: string): string {
    // tslint:disable-next-line
    for (const provider in this.oauth2ProvidersEmails) {
      for (const email of this.oauth2ProvidersEmails[provider]) {
        if (value.indexOf(email) !== -1) {
          return provider;
        }
      }
    }
    return this.checkOauth2FromHardcode(value);
  }

  private checkOauth2FromHardcode(value: string): string {
    // tslint:disable-next-line
    for (const provider in this.oauth2ProvidersEmailsHardCode) {
      for (const email of this.oauth2ProvidersEmailsHardCode[provider]) {
        if (value.indexOf(email) !== -1) {
          return provider;
        }
      }
    }
    // For now, every email not recognized should be sent to Azure
    return 'azure';
  }

  getAccessToken$(): Observable<any> {
    return from(this.oauthGetToken());
  }

  async setAuthConfig(oauth2Provider?: 'azure' | 'pingFederate' | 'okta' | string): Promise<any> {
    // Control the scenario where no provider still selected (therefore no previous session information either)
    this.oauth2Provider = oauth2Provider;
    console.log('AuthService setAuthConfig() oauth2Provider', oauth2Provider);
    if (!this.oauth2Provider) {
      this.oauth2Provider = await this.storage.get(PROVIDER_IN_USE);
    }

    if (!this.oauth2Provider) {
      await Promise.resolve();
      return;
    }

    if (oauth2Provider) {
      await this.storage.set(PROVIDER_IN_USE, oauth2Provider);
    }

    this.oauth2Config = this.config.config.security.providers[oauth2Provider];

    await Promise.resolve();
  }

  async oauthGetToken(): Promise<any> {
    let accessToken = null;
    if (this.platform.is('hybrid')) {
      accessToken = await this.storage.getSecure(ACCESS_TOKEN);
    } else {
      accessToken = await this.storage.get(ACCESS_TOKEN);
    }
    console.log('AuthService setAuthConfig() accessToken', accessToken);
    if (accessToken) {
      this.isLoggedIn$.next(true);
      return accessToken;
    } else {
      this.isLoggedIn$.next(false);
      this.router.navigate(['/' + AppUrls.AppLogin], { replaceUrl: true });
    }
  }

  async oAuthLogin(): Promise<any> {
    console.info(this.oauth2Config);

    return OAuth2Client.authenticate(
      this.oauth2Config
    ).then(async response => {
      console.info(response);
      console.log('AuthService oAuthLogin() Auth2Client.authenticate(response)', response);      
      if (this.platform.is('hybrid')) {
        await this.setTokenForHybridEnv(response);
      } else {
        this.accessToken = response.access_token;
        await this.storage.set(ACCESS_TOKEN, response.access_token);
        await this.storage.set(REFRESH_TOKEN, response.access_token);
        this.refreshToken = response.refresh_token;
        this.oauthUserId = response.id;
      }
      this.isLoggedIn$.next(true);
    }).catch(reason => {
      console.error('OAuth rejected', reason);
    });
  }

  async oAuthLogout(): Promise<void> {
    const providerId = await this.storage.get(PROVIDER_IN_USE);
    this.oauth2Config = this.config.config.security.providers[providerId];

    if (this.platform.is('ios') || this.platform.is('desktop')) {
      await Browser.open({ url: this.oauth2Config.logoutUrl })
        .finally(() => setTimeout(async () => {
          await this.clearAndExit();
        }, 4000));

    } else {
      // TODO: check if the oAuth session is correctly closed
      await Browser.open({ url: this.oauth2Config.logoutUrl })
        .finally(() => setTimeout(async () => {
          await this.storage.removeSecure('ACCESS_TOKEN');
          await this.storage.removeSecure('REFRESH_TOKEN');
          this.router.navigate(['/' + AppUrls.AppLogin], { replaceUrl: true });
        }, 2000));
    }
  }

  private async clearAndExit(): Promise<void> {
    await Browser.close();
    await this.storage.removeSecure('ACCESS_TOKEN');
    await this.storage.removeSecure('REFRESH_TOKEN');
    this.router.navigate(['/' + AppUrls.AppLogin], { replaceUrl: true });
  }

  private async setTokenForHybridEnv(response: HybridResponse): Promise<void> {
    const tokenResponse = response.access_token_response;

    this.accessToken = tokenResponse.access_token;
    await this.storage.setSecure(ACCESS_TOKEN, tokenResponse.access_token);
    this.refreshToken = tokenResponse.refresh_token;
    await this.storage.setSecure(REFRESH_TOKEN, tokenResponse.access_token);
  }
}
