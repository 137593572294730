import { Component, ViewEncapsulation } from '@angular/core';
import { InitService } from '@core/services/init/init.service';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Router } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(
    private readonly init: InitService,
    private readonly router: Router
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.init.init();
  }

  async closeScan(): Promise<void> {
    document.body.classList.remove('qrscanner');
    await BarcodeScanner.showBackground();
    await BarcodeScanner.stopScan();
    this.router.navigate(['/' + AppUrls.AppSpaces], { replaceUrl: true });
  }
}
