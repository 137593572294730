/**
 * Single file for all the app's internal paths
 */
export const AppUrls = {

  // Config TO-DO: define a remote url for config fetch and maybe set a default for offline purposes
  Config: 'config/config.json',

  // General
  App: '',
  AppRoot: '/',

  // Home
  AppHome: 'home',
  AppHomeRoot: '',

  // Login
  AppLogin: 'login',
  AppLoginRoot: '',

  // Spaces
  AppSpaces: 'spaces',
  AppSpacesRoot: '',
  AppSpaceCurrent: 'current',
  AppSpaceCurrentRoot: '',
  AppRequestSpace: 'request',
  AppRequestSpaceRoot: '',
  AppCalendarSpace: 'calendar',
  AppCalendarSpaceRoot: '',

  // Survey
  AppSurvey: 'survey',
  AppSurveyRoot: '',

  // Middleware (Development page)
  AppMiddleware: 'middleware',
  AppMiddlewareRoot: '',

  // Error
  AppError: 'error',

  // QR
  AppQr: 'qr'
};
