import { Injectable } from '@angular/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Storage } from '@capacitor/storage';

/**
 * Storage handling service that relies on Capacitor as a way to determine the best
 * strategy and storage to use depending on the running OS
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /**
   * Retrieves the storage data associated with the provided key
   * @param key: string
   */
  async get(key: string): Promise<any> {
    const item = await Storage.get({ key });
    console.log('StorageService get() ', item.value);
    return JSON.parse(item.value);
  }

  /**
   * Retrieves the secure storage data associated with the
   * provided key
   * @param key: string
   */
  async getSecure(key: string): Promise<any> {
    try {
      return SecureStoragePlugin.get({ key })
        .then(item => {
          console.log('StorageService getSecure() ', item.value);
          return JSON.parse(item.value);
        });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Sets the provided data using the provided key as reference on storage
   * @param key: string
   * @param value: any, and it will be stringified prior to be stored
   */
  async set(key: string, value: any): Promise<void> {
    await Storage.set({ key, value: JSON.stringify(value) });
  }

  /**
   * Sets the provided data securely using provided key as reference
   * In Web Apps, since no secure storage is available for the browser,
   * it encodes the info in base64
   * @param key: string
   * @param value: any, and it will be stringified prior to be stored
   */
  async setSecure(key: string, value: any): Promise<void> {
    await SecureStoragePlugin.set({ key, value: JSON.stringify(value) });
  }

  /**
   * Returns all the available data keys on storage
   */
  async keys(): Promise<any> {
    return await Storage.keys();
  }

  /**
   * Removes storage data associated to given key
   * @param key: string
   */
  async remove(key: string): Promise<void> {
    await Storage.remove({ key });
  }

  /**
   * Removes secure storage data associated to given key
   * @param key: string
   */
  async removeSecure(key: string): Promise<void> {
    await SecureStoragePlugin.remove({ key });
  }

  /**
   * Clears all the data currently on storage
   */
  async clear(): Promise<void> {
    await Storage.clear();
  }

  /**
   * Clears all the data currently on secure storage
   */
  async clearSecure(): Promise<void> {
    await SecureStoragePlugin.clear();
  }
}
