import { Component, OnInit } from '@angular/core';
import { SpacesHelperService } from '@core/services/spaces/spaces.service';
import { Requested, Spaces } from '@views/home/home.view';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalBottomComponent } from '@shared/components/modal-bottom/modal-bottom.component';
import { ModalController } from '@ionic/angular';
import { EmployeeService } from '@core/api/BE-DIGITAL-SURVEYS-API/services';
import { take } from 'rxjs/operators';
import { StateHandlerService } from '@core/services/state-handler/state-handler.service';

@Component({
  selector: 'app-card-request-pending',
  templateUrl: './card-request-pending.component.html',
  styleUrls: ['./card-request-pending.component.scss'],
})
export class CardRequestPendingComponent implements OnInit {
  selectRequest = false;
  spaces: Spaces;
  spacesRequestedPending: Requested[];

  constructor(private spacesHelperService: SpacesHelperService,
              private utilsService: UtilsService,
              private modalController: ModalController,
              private employeeService: EmployeeService,
              private stateHandlerService: StateHandlerService) {
  }

  ngOnInit() {
    this.refreshEmployee();
  }

  async deleteRequest(spaceRequest: Requested) {
    const deleteModal = await this.modalController.create({
      component: ModalBottomComponent,
      initialBreakpoint: 0.5,
      breakpoints: [0, 1],
      cssClass: 'modal-delete-space',
      componentProps: {
        requestId: spaceRequest.id,
        requestType: spaceRequest.type,
        isRequest: true
      }
    });
    deleteModal.onWillDismiss().then(_ => {
      this.refreshEmployee();
    });

    return await deleteModal.present();
  }

  private checkIfRequestedIsNotEmpty() {
    if (this.spaces.requested && this.spaces.requested.length > 0) {
      this.spacesRequestedPending = this.spaces.requested;
      this.parseSpacesRequested();
    } else {
      this.spacesRequestedPending = null;
    }
  }

  private parseSpacesRequested() {
    this.spacesRequestedPending.map(spaceRequest => {
      spaceRequest.dateExtended = this.utilsService.extractDate(spaceRequest.date);
    });
  }

  private async checkSpaceList() {
    this.spaces = await this.spacesHelperService.getSpaces();
    this.checkIfRequestedIsNotEmpty();
  }

  private refreshEmployee() {
    this.employeeService.getUser()
      .pipe(
        take(1))
      .subscribe(user => {
        this.spacesHelperService.emptySpaces();
        this.stateHandlerService.set('spaces', user.spaces);
        this.checkSpaceList();
      });
  }

}
