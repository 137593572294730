import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-steps-nav',
  templateUrl: './steps-nav.component.html',
  styleUrls: ['./steps-nav.component.scss'],
})
export class StepsNavComponent implements OnInit, OnChanges {
  @Input() items;
  @Input() indexQuestion;
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  controlNumber: number = 0;
  currentStep: number;
  enableBackBtn: boolean;
  enableNextBtn: boolean;
  steps: number;

  constructor() { }

  ngOnInit(): void {
    // Initialize the number of steps and the current step as the first one
    this.steps = this.items.length;
    this.currentStep = this.indexQuestion + 1;

    // Initialize steps buttons state
    this.updateButtons();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    for (const propertyName in changes) {
      // tslint:disable-next-line
      const changedProperty = changes[propertyName];
      if (!changedProperty.isFirstChange()) {
        this.currentStep = changedProperty.currentValue + 1;
        this.updateControlNumber(changedProperty.currentValue - changedProperty.previousValue);
        this.updateButtons();
      }
    }
  }

  move(intent: number): void {
    if (this.checkMove(intent)) {
      this.currentStep = this.currentStep + intent;
      this.updateButtons();
      this.navigate.emit(intent);
    }
  }

  /**
   * Checks if the user is trying to navigate to wrong states
   * @param intent: number
   */
  private checkMove(intent: number): boolean {
    return intent < 0 ?
      this.currentStep > 1 :
      this.steps !== this.currentStep &&
      this.controlNumber + intent <= 0;
  }

  private updateButtons(): void {
    this.enableBackBtn = this.currentStep > 1;
    this.enableNextBtn = this.controlNumber < 0 &&
      this.steps !== this.currentStep;
  }

  /**
   * Updates control number so as to determine user's navigation options (back and/or forward)
   * @param intent: number
   */
  private updateControlNumber(intent: number): void {
    this.controlNumber = this.controlNumber + intent > 0 ? 0 : this.controlNumber + intent;
  }

}
