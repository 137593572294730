import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Ionic
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';

// Bootstrap UI
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    NgbModule
  ],
  exports: [
    IonicModule,
    NgbModule
  ]
})
export class IonicArqModule {
  static forRoot(): ModuleWithProviders<IonicArqModule> {
    return {
      ngModule: IonicArqModule,
      providers: [
        {
          provide: RouteReuseStrategy,
          useClass: IonicRouteStrategy
        }
      ]
    };
  }
}
