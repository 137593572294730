<div class="answer-buttons">
  <!-- TWO BUTTONS -->
  <div class="form-block">
    <div class="d-flex ion-justify-content-evenly">
      <div class="fancy-check-button" *ngFor="let star of stars; let i = index;">
        <input
          type="radio"
          name="star"
          id="{{i}}"
          value="{{i + 1}}"
          (click)="nextStar($event,'survey-star-off')"
          [checked]="checked[i]"
        />
        <div class="fancy-check-mark">
          <i class="icon-survey-star-off"></i>
        </div>
      </div>
    </div>
    <!-- <i class="icon-survey-star"></i>
<i class="icon-survey-star-off"></i> -->
  </div>
</div>
